import { outfitTrainingDataService } from "@atorie/api/outfit-training-data";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export function outfitTrainingDataOptions() {
  return queryOptions({
    queryKey: ["outfit-training-data"],
    queryFn: () => outfitTrainingDataService.getAll(),
  });
}

export function useOutfitTrainingData() {
  return useSuspenseQuery(outfitTrainingDataOptions());
}
