import {shopifyService} from '@atorie/api/shopify'
import { StorefrontGenerated } from '@atorie/api/types'
import {queryOptions, useQuery, UseQueryResult} from '@tanstack/react-query'

export function shopifyProductsByIdsQueryOptions(ids: string[]) {
  return queryOptions({
    queryKey: ['shopify-products', ids] as const,
    queryFn: async ({queryKey: [_, ids]}) => {
      return await shopifyService.getProductsByIds(ids)
    },
  })
}

export interface ShopifyProductsByIdsQueryOptions {
  enabled?: boolean
}

export function useShopifyProductsByIdsQuery(
  ids: string[],
  queryOptions: ShopifyProductsByIdsQueryOptions = {},
): UseQueryResult<StorefrontGenerated.ProductCardFragment[]> {
  return useQuery({...shopifyProductsByIdsQueryOptions(ids), ...queryOptions})
}
