import {AxiosInstance, getAxiosInstance} from '@atorie/core/axios'

import {Paginate} from './types'
import {
  ProductCardFragment,
  ProductsQueryVariables,
} from './types/storefront.generated'

export interface Product {
  id: string
  name: string
  brand_name: string
  description: string | null
  image_key: string | null
  price: number | null
  sku: string | null
  owner_id: string | null
  colors: string[]
  features: string[]
  type: string | null
  finder_data: any | null
  urls: string[]
  published: boolean | null
  created_at: Date
  updated_at: Date
  brand_id: string | null
  collection_id: string | null
  three_d_object_id: string | null
  query_id?: string
  page?: number
  highlight_result?: any
}

export interface AlgoliaPaginated<T> {
  products: T[]
  page: number
  total_hits: number
  total_pages: number
  query_id: string
}

export interface AlgoliaPaginationQueryParams {
  page?: number
  limit?: number
}

export interface PaginationQueryParams {
  before?: string | null
  after?: string | null
  first?: number | null
  last?: number | null
}

export interface GetProductsQuery {
  filter?: string // TODO: Add filter type
  term?: string
  color?: string
  type?: string
  inCloset?: boolean
  page?: PaginationQueryParams
}

export interface AlgoliaSearchProductsQuery {
  filter?: string
  page?: AlgoliaPaginationQueryParams
}

export class ProductsService {
  private static instance: ProductsService
  private axios: AxiosInstance

  public static getInstance(): ProductsService {
    if (!ProductsService.instance) {
      ProductsService.instance = new ProductsService()
    }

    return ProductsService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async products(variables: ProductsQueryVariables = {}, signal?: AbortSignal) {
    console.log('variables', variables)
    const req = await this.axios.get<Paginate<ProductCardFragment[]>>(
      '/products',
      {
        params: {
          query: variables.query,
          limit: variables.first,
          cursor: variables.after,
          sortKey: variables.sortKey,
        },
        signal,
      },
    )

    return req.data
  }

  async createProduct(data: any, signal?: AbortSignal) {
    const req = await this.axios.post<any>(
      '/shopify/admin/products',
      {
        ...data,
      },
      {
        signal,
      },
    )

    return req.data
  }

  async productsSearch(
    query: AlgoliaSearchProductsQuery = {},
    signal?: AbortSignal,
  ) {
    const req = await this.axios.get<AlgoliaPaginated<Product>>(
      '/products/search',
      {
        params: {
          query: query.filter,
          ...query.page,
        },
        signal,
      },
    )

    return req.data
  }

  async productsByIds(ids: string[], signal?: AbortSignal) {
    const req = await this.axios.get<{products: Product[]; statusCode: number}>(
      '/products',
      {
        params: {
          ids,
        },
        signal,
      },
    )

    return req.data
  }

  async getRecommendations(signal?: AbortSignal) {
    const req = await this.axios.get<Paginate<Product>>(
      '/products/recommendations',
      {signal},
    )

    return req.data
  }

  async getSearchRecommendations(signal?: AbortSignal) {
    const req = await this.axios.get<AlgoliaPaginated<Product>>(
      '/products/recommendations',
      {signal},
    )

    return req.data
  }

  async getById(id: string) {
    const req = await this.axios.get(`/products/${id}`)
    return req.data.product as ProductCardFragment
  }

  search(query: string) {
    return this.axios.get(`/products/search?q=${query}`)
  }
}

export const productsService = ProductsService.getInstance()
