import {replicateService} from '@atorie/api/replicate'
import {queryOptions, useQuery} from '@tanstack/react-query'

type UseOutfitGenQueryOptions = Omit<
  ReturnType<typeof outfitGenQueryOptions>,
  'queryKey' | 'queryFn' | 'queryHash' | 'queryHashFn' | 'initialData'
>

export function outfitGenQueryOptions(
  outfit_description: string,
  model_description: string,
  index: number,
) {
  return queryOptions({
    queryKey: [
      'outfit-gen',
      outfit_description,
      model_description,
      index,
    ] as const,
    queryFn: async ({queryKey: [, outfit_description, model_description]}) => {
      const result = await replicateService.generateOutfits(
        outfit_description,
        model_description,
      )
      return result
    },
  })
}

export function useOutfitGen(
  outfit_description: string,
  model_description: string,
  index: number,
  options: Partial<UseOutfitGenQueryOptions> = {},
) {
  console.log('outfit_description', outfit_description)
  const outfitGenQuery = useQuery({
    ...outfitGenQueryOptions(outfit_description, model_description, index),
    ...options,
  })

  return {
    ...outfitGenQuery,
  }
}
