import {getAxiosInstance, type AxiosInstance} from '@atorie/core/axios'

export interface ProductRequestInput {
  name: string
  brand: string
  description: string
  image_keys: [string]
  link: string | null
  notify_email?: string | null
  product_id?: string | null
  owner_id?: string
}

export interface ProductRequest {
  id: string
  name: string
  brand: string
  description: string
  image_keys: string[]
  link: string | null
  notify_email: null
  created_at: string
  updated_at: string
  product_id: string | null
  owner_id: string | null
}

export enum ProductRequestStatus {
  pending = 'pending',
  approved = 'approved',
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export interface ProductRequestsQueryParams {
  status?: ProductRequestStatus
  order?: SortOrder
}

export class ProductRequestService {
  private static instance: ProductRequestService
  private axios: AxiosInstance

  public static getInstance(): ProductRequestService {
    if (!ProductRequestService.instance) {
      ProductRequestService.instance = new ProductRequestService()
    }

    return ProductRequestService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  create(productRequest: ProductRequestInput) {
    return this.axios.post<ProductRequest>('/product-requests', productRequest)
  }

  getAll(queryParams: ProductRequestsQueryParams = {}) {
    return this.axios.get<ProductRequest[]>('/product-requests', {
      params: queryParams,
    })
  }

  getById(id: string) {
    return this.axios.get<ProductRequest>(`/product-requests/${id}`)
  }

  update(id: string, input: Partial<ProductRequestInput>) {
    return this.axios.patch<ProductRequest>(`/product-requests/${id}`, input)
  }
}

export const productRequestService = ProductRequestService.getInstance()
