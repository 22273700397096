import {getAxiosInstance, type AxiosInstance} from '@atorie/core/axios'

export interface OutfitTrainingData {
  id: string
  image_url: string
  description: string
  potential_prompts: string[]
  style_tags: string[]
  person_of_interest: string
  created_at: string
  updated_at: string
}

export type CreateTrainingDataDto = Omit<
  OutfitTrainingData,
  'id' | 'created_at' | 'updated_at'
>
export type UpdateTrainingDataDto = Partial<CreateTrainingDataDto>

export class OutfitTrainingDataService {
  private static instance: OutfitTrainingDataService
  private axios: AxiosInstance

  public static getInstance(): OutfitTrainingDataService {
    if (!OutfitTrainingDataService.instance) {
      OutfitTrainingDataService.instance = new OutfitTrainingDataService()
    }

    return OutfitTrainingDataService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  create(data: CreateTrainingDataDto) {
    return this.axios.post<OutfitTrainingData>('/outfit-training-data', data)
  }

  getAll() {
    return this.axios.get<OutfitTrainingData[]>('/outfit-training-data')
  }

  getById(id: string) {
    return this.axios.get<OutfitTrainingData>(`/outfit-training-data/${id}`)
  }

  update(id: string, data: UpdateTrainingDataDto) {
    return this.axios.patch<OutfitTrainingData>(
      `/outfit-training-data/${id}`,
      data,
    )
  }

  delete(id: string) {
    return this.axios.delete<void>(`/outfit-training-data/${id}`)
  }

  async analyzeImage(imageUrl: string) {
    const response = await this.axios.post<CreateTrainingDataDto>(
      '/outfit-training-data/analyze-image',
      {
        image_url: imageUrl,
      },
    )
    return response
  }

  async search(query: string) {
    return this.axios.get<OutfitTrainingData[]>('/outfit-training-data/search', {
      params: { query },
    })
  }
}

export const outfitTrainingDataService = OutfitTrainingDataService.getInstance()
