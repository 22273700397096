import {FeaturedImage, getFeaturedImages, GetShopifyProductMatchParams} from '@atorie/api/admin'
import {queryOptions, useQuery, UseQueryResult} from '@tanstack/react-query'

export interface FeaturedImageFilter {
  published?: boolean
}

export function featuredImagesQueryOptions(filters: FeaturedImageFilter = {}) {
  return queryOptions({
    queryKey: ['shopify-products', 'featured-images', filters] as const,
    async queryFn({queryKey: [_, __, filters]}) {
      return await getFeaturedImages(filters)
    },
  })
}

export function useFeaturedImages(
  filters: FeaturedImageFilter = {},
): UseQueryResult<FeaturedImage[]> {
  return useQuery(featuredImagesQueryOptions(filters))
}
