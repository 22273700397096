import {updateFeaturedImage} from '@atorie/api/admin'
import {useMutation, useQueryClient} from '@tanstack/react-query'

import {featuredImagesQueryOptions} from './use-featured-images'

export function usePublishFeaturedImageMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    async mutationFn({id, isPublished}: {id: string; isPublished: boolean}) {
      return await updateFeaturedImage(id, {
        published: isPublished,
      })
    },
    onSuccess(data, vars, context) {
      queryClient.invalidateQueries(
        featuredImagesQueryOptions({published: false}),
      )

      queryClient.invalidateQueries(
        featuredImagesQueryOptions({published: true}),
      )
    },
  })
}
