import { userService } from "@atorie/api/users";
import {
  queryOptions,
  useQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

export function userNotificationRequestsQueryOption({
  searchId,
}: {
  searchId: string;
}) {
  return queryOptions({
    queryKey: ["users", "notification-requests", searchId] as const,
    async queryFn({ queryKey: [, , searchId] }) {
      return await userService.getNotificationRequest({ searchId });
    },
  });
}

export function useUserNotificationsBySearchId({
  searchId,
}: {
  searchId: string;
}) {
  return useQuery(userNotificationRequestsQueryOption({ searchId }));
}

export function useUserNotificationRequestsMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["users", "notification-requests"] as const,
    async mutationFn({ searchId }: { searchId: string }) {
      return await userService.sendNotificationRequestFor({ searchId });
    },
    onMutate: async ({ searchId }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries(
        userNotificationRequestsQueryOption({ searchId }),
      );

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(
        userNotificationRequestsQueryOption({ searchId }).queryKey,
      );

      // Optimistically update to the new value
      queryClient.setQueryData(
        userNotificationRequestsQueryOption({ searchId }).queryKey,
        (oldData) => ({
          ...(oldData as any),
          status: "pending",
        }),
      );

      // Return context with the previous data
      return { previousData };
    },
    onError: (error, variables, context) => {
      // Roll back to the previous value
      queryClient.setQueryData(
        userNotificationRequestsQueryOption({ searchId: variables.searchId })
          .queryKey,
        context?.previousData,
      );

      console.error("Failed to send notification request:", error);
    },
    onSettled(_, __, { searchId }) {
      queryClient.invalidateQueries(
        userNotificationRequestsQueryOption({ searchId }),
      );
    },
  });
}
