import {
  createShopifyMatch,
  type CreateShopifyMatchInput,
} from '@atorie/api/admin'
import {useMutation, useQueryClient} from '@tanstack/react-query'

import {shopifyMatchQueryOptions} from './use-shopify-match'

export function useCreateShopifyMatchMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    async mutationFn(shopifyMatch: CreateShopifyMatchInput) {
      return createShopifyMatch(shopifyMatch)
    },
    onSuccess({shopify_product_id, shopify_variant_id}) {
      queryClient.invalidateQueries(
        shopifyMatchQueryOptions({shopify_product_id, shopify_variant_id}),
      )
    },
  })
}
