import {
  uploadedImagesService,
  type UploadedImage,
} from "@atorie/api/uploaded-images";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

/**
 * Creates query options for fetching uploaded images by shopify product companion ID
 * @param companionId - The ID of the shopify product companion to fetch images for
 */
export const uploadedImagesByCompanionIdOptions = (companionId: string) => {
  return queryOptions({
    queryKey: ["uploaded-images", companionId] as const,
    queryFn: async ({ queryKey: [, id] }): Promise<UploadedImage[]> => {
      if (!id) {
        throw new Error("No companion id provided");
      }
      const response = await uploadedImagesService.findByCompanionId(id);
      return response as UploadedImage[];
    },
  });
};

/**
 * Hook to fetch uploaded images for a specific shopify product companion
 * @param companionId - The ID of the shopify product companion to fetch images for
 * @returns Query result containing the uploaded images
 */
export function useUploadedImagesByCompanionId(companionId: string) {
  return useSuspenseQuery(uploadedImagesByCompanionIdOptions(companionId));
}
