import { productCompanionService } from "@atorie/api/product-companion";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export function productCompanionByIdOptions(id: string) {
  return queryOptions({
    queryKey: ["product-companion", id],
    queryFn: ({ queryKey: [, id] }) => {
      if (!id) {
        throw new Error("No product companion id provided");
      }
      return productCompanionService.getProductCompanion(id);
    },
  });
}

export function useProductCompanionByIdQuery(id: string) {
  return useSuspenseQuery(productCompanionByIdOptions(id));
}
