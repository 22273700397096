import {shopifyService} from '@atorie/api/shopify'
import {PaginateInput, StorefrontTypes} from '@atorie/api/types'
import {infiniteQueryOptions, useInfiniteQuery} from '@tanstack/react-query'

const PRODUCTS_PER_PAGE = 60

function shopifyProductsQueryOptions({
  query,
  sortKey,
}: {query?: string; sortKey?: StorefrontTypes.ProductSortKeys} = {}) {
  const reverse =
    sortKey === StorefrontTypes.ProductSortKeys.Price ||
    sortKey === StorefrontTypes.ProductSortKeys.CreatedAt ||
    sortKey === StorefrontTypes.ProductSortKeys.UpdatedAt
  return infiniteQueryOptions({
    queryKey: ['shopify-admin-products', {query, sortKey, reverse}] as const,
    queryFn: async ({pageParam}) => {
      return await shopifyService.getAdminProducts({
        ...pageParam,
        query,
        sortKey,
        reverse,
      })
    },
    getNextPageParam(lastPage) {
      if (!lastPage) {
        return
      }
      if (!lastPage.pageInfo.hasNextPage) {
        return
      }

      return {
        after: lastPage.pageInfo.endCursor,
        first: PRODUCTS_PER_PAGE,
      }
    },
    getPreviousPageParam(firstPage) {
      if (!firstPage) {
        return
      }
      if (!firstPage.pageInfo.hasPreviousPage) {
        return
      }

      return {
        before: firstPage.pageInfo.startCursor,
        last: PRODUCTS_PER_PAGE,
      }
    },
    select(data) {
      return data
    },
    initialData: undefined,
    initialPageParam: {
      first: PRODUCTS_PER_PAGE,
    } as PaginateInput,
  })
}

export function useShopifyAdminProductsInfiniteQuery({
  query,
  sortKey,
}: {query?: string; sortKey?: StorefrontTypes.ProductSortKeys} = {}) {
  return useInfiniteQuery(shopifyProductsQueryOptions({query, sortKey}))
}
