import {getAxiosInstance, type AxiosInstance} from '@atorie/core/axios'

export class ReplicateService {
  private static instance: ReplicateService
  private axios: AxiosInstance

  public static getInstance(): ReplicateService {
    if (!ReplicateService.instance) {
      ReplicateService.instance = new ReplicateService()
    }

    return ReplicateService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async generateOutfits(
    outfit_description: string,
    model_description: string,
    outfit_count: number = 1,
  ): Promise<string[]> {
    const requests = Array.from({length: outfit_count}, () =>
      this.axios.post<any>('/replicate/outfit-gen', {
        outfit_description,
        model_description,
      }),
    )
    const responses = await Promise.all(requests)
    const result = responses.flatMap((response) => response.data as string)
    return result
  }

  async generateOutfitsRoom(
    outfit_descriptions: string[],
    prompt: string,
    model_description: string,
  ): Promise<OutfitResponse[]> {
    const response = await this.axios.post<any>('/replicate/outfit-gen-room', {
      outfit_descriptions,
      prompt,
      model_description,
    })
    console.log('response', response)
    return response.data as OutfitResponse[]
  }
}

export interface OutfitResponse {
  description: string
  image_url: string
}

export const replicateService = ReplicateService.getInstance()
