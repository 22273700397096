import {deleteShopifyMatch} from '@atorie/api/admin'
import {useMutation, useQueryClient} from '@tanstack/react-query'

import {shopifyMatchQueryOptions} from './use-shopify-match'

type DeleteShopifyMatchInput = {
  id: string
  shopify_product_id: string
  shopify_variant_id: string
}

export function useDeleteShopifyMatchMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    async mutationFn(shopifyMatch: DeleteShopifyMatchInput) {
      return deleteShopifyMatch(shopifyMatch.id)
    },
    onSuccess(_, {shopify_product_id, shopify_variant_id}) {
      queryClient.invalidateQueries(
        shopifyMatchQueryOptions({shopify_product_id, shopify_variant_id}),
      )
    },
  })
}
