import {
  createFeaturedImage,
  type CreateFeaturedImageInput,
} from '@atorie/api/admin'
import {useMutation, useQueryClient} from '@tanstack/react-query'

export function useCreateFeaturedImageMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    async mutationFn(createFeaturedImageInput: CreateFeaturedImageInput) {
      return await createFeaturedImage(createFeaturedImageInput)
    },
    onSuccess() {
      queryClient.invalidateQueries({
        predicate: (query) => {
          const isMatchQuery = query.queryKey[0] === 'shopify-match'

          if (!isMatchQuery) {
            return false
          }
          const params = query.queryKey[1]

          if (typeof params !== 'object') {
            return false
          }

          if (params === null) {
            return false
          }

          return Object.keys(params).every((key) =>
            ['shopify_product_id', 'shopify_variant_id'].includes(key),
          )
        },
      })
    },
  })
}
