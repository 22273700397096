/**
 * Returns the full URL of the image on the CDN
 * @param path - the path of the image on the storage
 * @param rootPath - the root path of the image on the storage
 * @returns
 */
export function imageURLFromCDN(path: string, rootPath = 'public'): string{
  const alreadyFullURL = path.startsWith('http') || path.startsWith('data:')
  if (alreadyFullURL) return path
  const host = process.env.NEXT_PUBLIC_CLOUDFRONT_URL!

  // NOTE: the return value could be replaced with the fallback image
  if (!path) return ''

  if (path.startsWith(rootPath)) {
    return [host, path].join('/')
  }

  return [host, rootPath, path].join('/')
}
