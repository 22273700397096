import { productCompanionService } from "@atorie/api/product-companion";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export function publicProductCompanionByIdOptions(id: string) {
  return queryOptions({
    queryKey: ["public-product-companion", id],
    queryFn: ({ queryKey: [, id] }) => {
      if (!id) {
        throw new Error("No product companion id provided");
      }
      return productCompanionService.getPublicProductCompanion(id);
    },
  });
}

export function usePublicProductCompanionByIdQuery(id: string) {
  return useSuspenseQuery(publicProductCompanionByIdOptions(id));
}
