'use client'

import {createContext, useCallback, useEffect, useState, type ReactNode} from 'react'

export type  PreviewImageContextValue ={
  previewImage: string | null
  updatePreviewImage: (image: string | File) => void
  file: File | null
}

export const PreviewImageContext = createContext<PreviewImageContextValue>({
  previewImage: null,
  updatePreviewImage: () => {},
  file: null,
})

export type PreviewImageProviderProps = {
  children: ReactNode
}


export function PreviewImageProvider({children}: PreviewImageProviderProps) {
  const [previewImage, setPreviewImage] = useState<string | null>(null)
  const [file, setFile] = useState<File | null>(null)

  const updatePreviewImage = useCallback(
    (image: string | File) => {
      URL.revokeObjectURL(previewImage ?? '')

      if (typeof image === 'string') {
        setPreviewImage(image)
      } else {
        setFile(image)
        setPreviewImage(URL.createObjectURL(image))
      }
    },
    [previewImage],
  )

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(previewImage ?? '')
    }
  }, [previewImage])

  return (
    <PreviewImageContext.Provider
      value={{previewImage, file, updatePreviewImage}}
    >
      {children}
    </PreviewImageContext.Provider>
  )
}
