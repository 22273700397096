/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type DeleteVisionSearchEntryInput = {
  id: string,
};

export type ModelVisionSearchEntryConditionInput = {
  user_id?: ModelIDInput | null,
  image_url?: ModelStringInput | null,
  result?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelVisionSearchEntryConditionInput | null > | null,
  or?: Array< ModelVisionSearchEntryConditionInput | null > | null,
  not?: ModelVisionSearchEntryConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type VisionSearchEntry = {
  __typename: "VisionSearchEntry",
  id: string,
  user_id: string,
  image_url: string,
  result?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CreateStylistRoomInviteInput = {
  id?: string | null,
  stylistRoomId: string,
  completed: boolean,
  completedAt?: string | null,
  createdAt?: string | null,
};

export type ModelStylistRoomInviteConditionInput = {
  stylistRoomId?: ModelIDInput | null,
  completed?: ModelBooleanInput | null,
  completedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelStylistRoomInviteConditionInput | null > | null,
  or?: Array< ModelStylistRoomInviteConditionInput | null > | null,
  not?: ModelStylistRoomInviteConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type StylistRoomInvite = {
  __typename: "StylistRoomInvite",
  id: string,
  stylistRoomId: string,
  completed: boolean,
  completedAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateStylistRoomInviteInput = {
  id: string,
  stylistRoomId?: string | null,
  completed?: boolean | null,
  completedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteStylistRoomInviteInput = {
  id: string,
};

export type CreateCloneTrainingJobInput = {
  id?: string | null,
  user_id: string,
  status: string,
  progress: number,
  clone_id?: string | null,
  is_product_clone: boolean,
  error?: string | null,
  completed_at?: string | null,
  duration?: number | null,
  createdAt?: string | null,
};

export type ModelCloneTrainingJobConditionInput = {
  user_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  progress?: ModelIntInput | null,
  clone_id?: ModelStringInput | null,
  is_product_clone?: ModelBooleanInput | null,
  error?: ModelStringInput | null,
  completed_at?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCloneTrainingJobConditionInput | null > | null,
  or?: Array< ModelCloneTrainingJobConditionInput | null > | null,
  not?: ModelCloneTrainingJobConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type CloneTrainingJob = {
  __typename: "CloneTrainingJob",
  id: string,
  user_id: string,
  status: string,
  progress: number,
  clone_id?: string | null,
  is_product_clone: boolean,
  error?: string | null,
  completed_at?: string | null,
  duration?: number | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type DeleteCloneTrainingJobInput = {
  id: string,
};

export type CreateSavedProductInput = {
  user_id: string,
  product_id: string,
  createdAt?: string | null,
};

export type ModelSavedProductConditionInput = {
  createdAt?: ModelStringInput | null,
  and?: Array< ModelSavedProductConditionInput | null > | null,
  or?: Array< ModelSavedProductConditionInput | null > | null,
  not?: ModelSavedProductConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type SavedProduct = {
  __typename: "SavedProduct",
  user_id: string,
  product_id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateSavedProductInput = {
  user_id: string,
  product_id: string,
  createdAt?: string | null,
};

export type DeleteSavedProductInput = {
  user_id: string,
  product_id: string,
};

export type CreateUserFavoriteStoreInput = {
  user_id: string,
  store_id: string,
  createdAt?: string | null,
};

export type ModelUserFavoriteStoreConditionInput = {
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserFavoriteStoreConditionInput | null > | null,
  or?: Array< ModelUserFavoriteStoreConditionInput | null > | null,
  not?: ModelUserFavoriteStoreConditionInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UserFavoriteStore = {
  __typename: "UserFavoriteStore",
  user_id: string,
  store_id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserFavoriteStoreInput = {
  user_id: string,
  store_id: string,
  createdAt?: string | null,
};

export type DeleteUserFavoriteStoreInput = {
  user_id: string,
  store_id: string,
};

export type CreateVisionSearchEntryInput = {
  id?: string | null,
  user_id: string,
  image_url: string,
  result?: string | null,
  createdAt?: string | null,
};

export type UpdateVisionSearchEntryInput = {
  id: string,
  user_id?: string | null,
  image_url?: string | null,
  result?: string | null,
  createdAt?: string | null,
};

export type CreateStylistRoomInput = {
  id?: string | null,
  name: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  users?: Array< string | null > | null,
  userIds?: Array< string | null > | null,
  modelBuild?: string | null,
  userStatus?: string | null,
};

export type ModelStylistRoomConditionInput = {
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  users?: ModelStringInput | null,
  userIds?: ModelIDInput | null,
  modelBuild?: ModelStringInput | null,
  userStatus?: ModelStringInput | null,
  and?: Array< ModelStylistRoomConditionInput | null > | null,
  or?: Array< ModelStylistRoomConditionInput | null > | null,
  not?: ModelStylistRoomConditionInput | null,
};

export type StylistRoom = {
  __typename: "StylistRoom",
  id: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  users?: Array< string | null > | null,
  userIds?: Array< string | null > | null,
  entries?: ModelStylistRoomEntryConnection | null,
  modelBuild?: string | null,
  userStatus?: string | null,
};

export type ModelStylistRoomEntryConnection = {
  __typename: "ModelStylistRoomEntryConnection",
  items:  Array<StylistRoomEntry | null >,
  nextToken?: string | null,
};

export type StylistRoomEntry = {
  __typename: "StylistRoomEntry",
  id: string,
  stylistRoomId: string,
  senderId: string,
  content: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateStylistRoomInput = {
  id: string,
  name?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  users?: Array< string | null > | null,
  userIds?: Array< string | null > | null,
  modelBuild?: string | null,
  userStatus?: string | null,
};

export type DeleteStylistRoomInput = {
  id: string,
};

export type CreateStylistRoomEntryInput = {
  id?: string | null,
  stylistRoomId: string,
  senderId: string,
  content: string,
  createdAt?: string | null,
};

export type ModelStylistRoomEntryConditionInput = {
  stylistRoomId?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelStylistRoomEntryConditionInput | null > | null,
  or?: Array< ModelStylistRoomEntryConditionInput | null > | null,
  not?: ModelStylistRoomEntryConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateStylistRoomEntryInput = {
  id: string,
  stylistRoomId?: string | null,
  senderId?: string | null,
  content?: string | null,
  createdAt?: string | null,
};

export type DeleteStylistRoomEntryInput = {
  id: string,
};

export type UpdateCloneTrainingJobInput = {
  id: string,
  user_id?: string | null,
  status?: string | null,
  progress?: number | null,
  clone_id?: string | null,
  is_product_clone?: boolean | null,
  error?: string | null,
  completed_at?: string | null,
  duration?: number | null,
  createdAt?: string | null,
};

export type ModelCloneTrainingJobFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  progress?: ModelIntInput | null,
  clone_id?: ModelStringInput | null,
  is_product_clone?: ModelBooleanInput | null,
  error?: ModelStringInput | null,
  completed_at?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCloneTrainingJobFilterInput | null > | null,
  or?: Array< ModelCloneTrainingJobFilterInput | null > | null,
  not?: ModelCloneTrainingJobFilterInput | null,
  owner?: ModelStringInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCloneTrainingJobConnection = {
  __typename: "ModelCloneTrainingJobConnection",
  items:  Array<CloneTrainingJob | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSavedProductFilterInput = {
  user_id?: ModelIDInput | null,
  product_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSavedProductFilterInput | null > | null,
  or?: Array< ModelSavedProductFilterInput | null > | null,
  not?: ModelSavedProductFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSavedProductConnection = {
  __typename: "ModelSavedProductConnection",
  items:  Array<SavedProduct | null >,
  nextToken?: string | null,
};

export type ModelUserFavoriteStoreFilterInput = {
  user_id?: ModelIDInput | null,
  store_id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFavoriteStoreFilterInput | null > | null,
  or?: Array< ModelUserFavoriteStoreFilterInput | null > | null,
  not?: ModelUserFavoriteStoreFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelUserFavoriteStoreConnection = {
  __typename: "ModelUserFavoriteStoreConnection",
  items:  Array<UserFavoriteStore | null >,
  nextToken?: string | null,
};

export type ModelVisionSearchEntryFilterInput = {
  id?: ModelIDInput | null,
  user_id?: ModelIDInput | null,
  image_url?: ModelStringInput | null,
  result?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVisionSearchEntryFilterInput | null > | null,
  or?: Array< ModelVisionSearchEntryFilterInput | null > | null,
  not?: ModelVisionSearchEntryFilterInput | null,
};

export type ModelVisionSearchEntryConnection = {
  __typename: "ModelVisionSearchEntryConnection",
  items:  Array<VisionSearchEntry | null >,
  nextToken?: string | null,
};

export type ModelStylistRoomFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  users?: ModelStringInput | null,
  userIds?: ModelIDInput | null,
  modelBuild?: ModelStringInput | null,
  userStatus?: ModelStringInput | null,
  and?: Array< ModelStylistRoomFilterInput | null > | null,
  or?: Array< ModelStylistRoomFilterInput | null > | null,
  not?: ModelStylistRoomFilterInput | null,
};

export type ModelStylistRoomConnection = {
  __typename: "ModelStylistRoomConnection",
  items:  Array<StylistRoom | null >,
  nextToken?: string | null,
};

export type ModelStylistRoomInviteFilterInput = {
  id?: ModelIDInput | null,
  stylistRoomId?: ModelIDInput | null,
  completed?: ModelBooleanInput | null,
  completedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStylistRoomInviteFilterInput | null > | null,
  or?: Array< ModelStylistRoomInviteFilterInput | null > | null,
  not?: ModelStylistRoomInviteFilterInput | null,
};

export type ModelStylistRoomInviteConnection = {
  __typename: "ModelStylistRoomInviteConnection",
  items:  Array<StylistRoomInvite | null >,
  nextToken?: string | null,
};

export type ModelStylistRoomEntryFilterInput = {
  id?: ModelIDInput | null,
  stylistRoomId?: ModelIDInput | null,
  senderId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStylistRoomEntryFilterInput | null > | null,
  or?: Array< ModelStylistRoomEntryFilterInput | null > | null,
  not?: ModelStylistRoomEntryFilterInput | null,
};

export type ModelSubscriptionCloneTrainingJobFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  progress?: ModelSubscriptionIntInput | null,
  clone_id?: ModelSubscriptionStringInput | null,
  is_product_clone?: ModelSubscriptionBooleanInput | null,
  error?: ModelSubscriptionStringInput | null,
  completed_at?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCloneTrainingJobFilterInput | null > | null,
  or?: Array< ModelSubscriptionCloneTrainingJobFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionSavedProductFilterInput = {
  user_id?: ModelSubscriptionIDInput | null,
  product_id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSavedProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionSavedProductFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionUserFavoriteStoreFilterInput = {
  user_id?: ModelSubscriptionIDInput | null,
  store_id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFavoriteStoreFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFavoriteStoreFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionVisionSearchEntryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user_id?: ModelSubscriptionIDInput | null,
  image_url?: ModelSubscriptionStringInput | null,
  result?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVisionSearchEntryFilterInput | null > | null,
  or?: Array< ModelSubscriptionVisionSearchEntryFilterInput | null > | null,
};

export type ModelSubscriptionStylistRoomFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  userIds?: ModelSubscriptionIDInput | null,
  modelBuild?: ModelSubscriptionStringInput | null,
  userStatus?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStylistRoomFilterInput | null > | null,
  or?: Array< ModelSubscriptionStylistRoomFilterInput | null > | null,
};

export type ModelSubscriptionStylistRoomInviteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  stylistRoomId?: ModelSubscriptionIDInput | null,
  completed?: ModelSubscriptionBooleanInput | null,
  completedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStylistRoomInviteFilterInput | null > | null,
  or?: Array< ModelSubscriptionStylistRoomInviteFilterInput | null > | null,
};

export type ModelSubscriptionStylistRoomEntryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  stylistRoomId?: ModelSubscriptionIDInput | null,
  senderId?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStylistRoomEntryFilterInput | null > | null,
  or?: Array< ModelSubscriptionStylistRoomEntryFilterInput | null > | null,
};

export type DeleteVisionSearchEntryMutationVariables = {
  input: DeleteVisionSearchEntryInput,
  condition?: ModelVisionSearchEntryConditionInput | null,
};

export type DeleteVisionSearchEntryMutation = {
  deleteVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStylistRoomInviteMutationVariables = {
  input: CreateStylistRoomInviteInput,
  condition?: ModelStylistRoomInviteConditionInput | null,
};

export type CreateStylistRoomInviteMutation = {
  createStylistRoomInvite?:  {
    __typename: "StylistRoomInvite",
    id: string,
    stylistRoomId: string,
    completed: boolean,
    completedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStylistRoomInviteMutationVariables = {
  input: UpdateStylistRoomInviteInput,
  condition?: ModelStylistRoomInviteConditionInput | null,
};

export type UpdateStylistRoomInviteMutation = {
  updateStylistRoomInvite?:  {
    __typename: "StylistRoomInvite",
    id: string,
    stylistRoomId: string,
    completed: boolean,
    completedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStylistRoomInviteMutationVariables = {
  input: DeleteStylistRoomInviteInput,
  condition?: ModelStylistRoomInviteConditionInput | null,
};

export type DeleteStylistRoomInviteMutation = {
  deleteStylistRoomInvite?:  {
    __typename: "StylistRoomInvite",
    id: string,
    stylistRoomId: string,
    completed: boolean,
    completedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCloneTrainingJobMutationVariables = {
  input: CreateCloneTrainingJobInput,
  condition?: ModelCloneTrainingJobConditionInput | null,
};

export type CreateCloneTrainingJobMutation = {
  createCloneTrainingJob?:  {
    __typename: "CloneTrainingJob",
    id: string,
    user_id: string,
    status: string,
    progress: number,
    clone_id?: string | null,
    is_product_clone: boolean,
    error?: string | null,
    completed_at?: string | null,
    duration?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCloneTrainingJobMutationVariables = {
  input: DeleteCloneTrainingJobInput,
  condition?: ModelCloneTrainingJobConditionInput | null,
};

export type DeleteCloneTrainingJobMutation = {
  deleteCloneTrainingJob?:  {
    __typename: "CloneTrainingJob",
    id: string,
    user_id: string,
    status: string,
    progress: number,
    clone_id?: string | null,
    is_product_clone: boolean,
    error?: string | null,
    completed_at?: string | null,
    duration?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSavedProductMutationVariables = {
  input: CreateSavedProductInput,
  condition?: ModelSavedProductConditionInput | null,
};

export type CreateSavedProductMutation = {
  createSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSavedProductMutationVariables = {
  input: UpdateSavedProductInput,
  condition?: ModelSavedProductConditionInput | null,
};

export type UpdateSavedProductMutation = {
  updateSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSavedProductMutationVariables = {
  input: DeleteSavedProductInput,
  condition?: ModelSavedProductConditionInput | null,
};

export type DeleteSavedProductMutation = {
  deleteSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserFavoriteStoreMutationVariables = {
  input: CreateUserFavoriteStoreInput,
  condition?: ModelUserFavoriteStoreConditionInput | null,
};

export type CreateUserFavoriteStoreMutation = {
  createUserFavoriteStore?:  {
    __typename: "UserFavoriteStore",
    user_id: string,
    store_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserFavoriteStoreMutationVariables = {
  input: UpdateUserFavoriteStoreInput,
  condition?: ModelUserFavoriteStoreConditionInput | null,
};

export type UpdateUserFavoriteStoreMutation = {
  updateUserFavoriteStore?:  {
    __typename: "UserFavoriteStore",
    user_id: string,
    store_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserFavoriteStoreMutationVariables = {
  input: DeleteUserFavoriteStoreInput,
  condition?: ModelUserFavoriteStoreConditionInput | null,
};

export type DeleteUserFavoriteStoreMutation = {
  deleteUserFavoriteStore?:  {
    __typename: "UserFavoriteStore",
    user_id: string,
    store_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateVisionSearchEntryMutationVariables = {
  input: CreateVisionSearchEntryInput,
  condition?: ModelVisionSearchEntryConditionInput | null,
};

export type CreateVisionSearchEntryMutation = {
  createVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVisionSearchEntryMutationVariables = {
  input: UpdateVisionSearchEntryInput,
  condition?: ModelVisionSearchEntryConditionInput | null,
};

export type UpdateVisionSearchEntryMutation = {
  updateVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStylistRoomMutationVariables = {
  input: CreateStylistRoomInput,
  condition?: ModelStylistRoomConditionInput | null,
};

export type CreateStylistRoomMutation = {
  createStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?: Array< string | null > | null,
    userIds?: Array< string | null > | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type UpdateStylistRoomMutationVariables = {
  input: UpdateStylistRoomInput,
  condition?: ModelStylistRoomConditionInput | null,
};

export type UpdateStylistRoomMutation = {
  updateStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?: Array< string | null > | null,
    userIds?: Array< string | null > | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type DeleteStylistRoomMutationVariables = {
  input: DeleteStylistRoomInput,
  condition?: ModelStylistRoomConditionInput | null,
};

export type DeleteStylistRoomMutation = {
  deleteStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?: Array< string | null > | null,
    userIds?: Array< string | null > | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type CreateStylistRoomEntryMutationVariables = {
  input: CreateStylistRoomEntryInput,
  condition?: ModelStylistRoomEntryConditionInput | null,
};

export type CreateStylistRoomEntryMutation = {
  createStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStylistRoomEntryMutationVariables = {
  input: UpdateStylistRoomEntryInput,
  condition?: ModelStylistRoomEntryConditionInput | null,
};

export type UpdateStylistRoomEntryMutation = {
  updateStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStylistRoomEntryMutationVariables = {
  input: DeleteStylistRoomEntryInput,
  condition?: ModelStylistRoomEntryConditionInput | null,
};

export type DeleteStylistRoomEntryMutation = {
  deleteStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCloneTrainingJobMutationVariables = {
  input: UpdateCloneTrainingJobInput,
  condition?: ModelCloneTrainingJobConditionInput | null,
};

export type UpdateCloneTrainingJobMutation = {
  updateCloneTrainingJob?:  {
    __typename: "CloneTrainingJob",
    id: string,
    user_id: string,
    status: string,
    progress: number,
    clone_id?: string | null,
    is_product_clone: boolean,
    error?: string | null,
    completed_at?: string | null,
    duration?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type GetCloneTrainingJobQueryVariables = {
  id: string,
};

export type GetCloneTrainingJobQuery = {
  getCloneTrainingJob?:  {
    __typename: "CloneTrainingJob",
    id: string,
    user_id: string,
    status: string,
    progress: number,
    clone_id?: string | null,
    is_product_clone: boolean,
    error?: string | null,
    completed_at?: string | null,
    duration?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCloneTrainingJobsQueryVariables = {
  id?: string | null,
  filter?: ModelCloneTrainingJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCloneTrainingJobsQuery = {
  listCloneTrainingJobs?:  {
    __typename: "ModelCloneTrainingJobConnection",
    items:  Array< {
      __typename: "CloneTrainingJob",
      id: string,
      user_id: string,
      status: string,
      progress: number,
      clone_id?: string | null,
      is_product_clone: boolean,
      error?: string | null,
      completed_at?: string | null,
      duration?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CloneTrainingJobsByUserIdQueryVariables = {
  user_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCloneTrainingJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CloneTrainingJobsByUserIdQuery = {
  cloneTrainingJobsByUserId?:  {
    __typename: "ModelCloneTrainingJobConnection",
    items:  Array< {
      __typename: "CloneTrainingJob",
      id: string,
      user_id: string,
      status: string,
      progress: number,
      clone_id?: string | null,
      is_product_clone: boolean,
      error?: string | null,
      completed_at?: string | null,
      duration?: number | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSavedProductQueryVariables = {
  user_id: string,
  product_id: string,
};

export type GetSavedProductQuery = {
  getSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSavedProductsQueryVariables = {
  user_id?: string | null,
  product_id?: ModelIDKeyConditionInput | null,
  filter?: ModelSavedProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSavedProductsQuery = {
  listSavedProducts?:  {
    __typename: "ModelSavedProductConnection",
    items:  Array< {
      __typename: "SavedProduct",
      user_id: string,
      product_id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SavedProductsByUserQueryVariables = {
  user_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSavedProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SavedProductsByUserQuery = {
  savedProductsByUser?:  {
    __typename: "ModelSavedProductConnection",
    items:  Array< {
      __typename: "SavedProduct",
      user_id: string,
      product_id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SavesForProductQueryVariables = {
  product_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSavedProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SavesForProductQuery = {
  savesForProduct?:  {
    __typename: "ModelSavedProductConnection",
    items:  Array< {
      __typename: "SavedProduct",
      user_id: string,
      product_id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserFavoriteStoreQueryVariables = {
  user_id: string,
  store_id: string,
};

export type GetUserFavoriteStoreQuery = {
  getUserFavoriteStore?:  {
    __typename: "UserFavoriteStore",
    user_id: string,
    store_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserFavoriteStoresQueryVariables = {
  user_id?: string | null,
  store_id?: ModelIDKeyConditionInput | null,
  filter?: ModelUserFavoriteStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserFavoriteStoresQuery = {
  listUserFavoriteStores?:  {
    __typename: "ModelUserFavoriteStoreConnection",
    items:  Array< {
      __typename: "UserFavoriteStore",
      user_id: string,
      store_id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FavoriteStoresByUserQueryVariables = {
  user_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFavoriteStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FavoriteStoresByUserQuery = {
  favoriteStoresByUser?:  {
    __typename: "ModelUserFavoriteStoreConnection",
    items:  Array< {
      __typename: "UserFavoriteStore",
      user_id: string,
      store_id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FavoritesForStoreQueryVariables = {
  store_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFavoriteStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FavoritesForStoreQuery = {
  favoritesForStore?:  {
    __typename: "ModelUserFavoriteStoreConnection",
    items:  Array< {
      __typename: "UserFavoriteStore",
      user_id: string,
      store_id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVisionSearchEntryQueryVariables = {
  id: string,
};

export type GetVisionSearchEntryQuery = {
  getVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVisionSearchEntriesQueryVariables = {
  id?: string | null,
  filter?: ModelVisionSearchEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListVisionSearchEntriesQuery = {
  listVisionSearchEntries?:  {
    __typename: "ModelVisionSearchEntryConnection",
    items:  Array< {
      __typename: "VisionSearchEntry",
      id: string,
      user_id: string,
      image_url: string,
      result?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VisionSearchEntriesByUserIdQueryVariables = {
  user_id: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVisionSearchEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VisionSearchEntriesByUserIdQuery = {
  visionSearchEntriesByUserId?:  {
    __typename: "ModelVisionSearchEntryConnection",
    items:  Array< {
      __typename: "VisionSearchEntry",
      id: string,
      user_id: string,
      image_url: string,
      result?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStylistRoomQueryVariables = {
  id: string,
};

export type GetStylistRoomQuery = {
  getStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?: Array< string | null > | null,
    userIds?: Array< string | null > | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type ListStylistRoomsQueryVariables = {
  id?: string | null,
  filter?: ModelStylistRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStylistRoomsQuery = {
  listStylistRooms?:  {
    __typename: "ModelStylistRoomConnection",
    items:  Array< {
      __typename: "StylistRoom",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      users?: Array< string | null > | null,
      userIds?: Array< string | null > | null,
      modelBuild?: string | null,
      userStatus?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStylistRoomInviteQueryVariables = {
  id: string,
};

export type GetStylistRoomInviteQuery = {
  getStylistRoomInvite?:  {
    __typename: "StylistRoomInvite",
    id: string,
    stylistRoomId: string,
    completed: boolean,
    completedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStylistRoomInvitesQueryVariables = {
  id?: string | null,
  filter?: ModelStylistRoomInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStylistRoomInvitesQuery = {
  listStylistRoomInvites?:  {
    __typename: "ModelStylistRoomInviteConnection",
    items:  Array< {
      __typename: "StylistRoomInvite",
      id: string,
      stylistRoomId: string,
      completed: boolean,
      completedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStylistRoomEntryQueryVariables = {
  id: string,
};

export type GetStylistRoomEntryQuery = {
  getStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStylistRoomEntriesQueryVariables = {
  id?: string | null,
  filter?: ModelStylistRoomEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStylistRoomEntriesQuery = {
  listStylistRoomEntries?:  {
    __typename: "ModelStylistRoomEntryConnection",
    items:  Array< {
      __typename: "StylistRoomEntry",
      id: string,
      stylistRoomId: string,
      senderId: string,
      content: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StylistRoomEntriesByStylistRoomIdQueryVariables = {
  stylistRoomId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStylistRoomEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StylistRoomEntriesByStylistRoomIdQuery = {
  stylistRoomEntriesByStylistRoomId?:  {
    __typename: "ModelStylistRoomEntryConnection",
    items:  Array< {
      __typename: "StylistRoomEntry",
      id: string,
      stylistRoomId: string,
      senderId: string,
      content: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCloneTrainingJobSubscriptionVariables = {
  filter?: ModelSubscriptionCloneTrainingJobFilterInput | null,
  owner?: string | null,
};

export type OnCreateCloneTrainingJobSubscription = {
  onCreateCloneTrainingJob?:  {
    __typename: "CloneTrainingJob",
    id: string,
    user_id: string,
    status: string,
    progress: number,
    clone_id?: string | null,
    is_product_clone: boolean,
    error?: string | null,
    completed_at?: string | null,
    duration?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCloneTrainingJobSubscriptionVariables = {
  filter?: ModelSubscriptionCloneTrainingJobFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCloneTrainingJobSubscription = {
  onUpdateCloneTrainingJob?:  {
    __typename: "CloneTrainingJob",
    id: string,
    user_id: string,
    status: string,
    progress: number,
    clone_id?: string | null,
    is_product_clone: boolean,
    error?: string | null,
    completed_at?: string | null,
    duration?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCloneTrainingJobSubscriptionVariables = {
  filter?: ModelSubscriptionCloneTrainingJobFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCloneTrainingJobSubscription = {
  onDeleteCloneTrainingJob?:  {
    __typename: "CloneTrainingJob",
    id: string,
    user_id: string,
    status: string,
    progress: number,
    clone_id?: string | null,
    is_product_clone: boolean,
    error?: string | null,
    completed_at?: string | null,
    duration?: number | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSavedProductSubscriptionVariables = {
  filter?: ModelSubscriptionSavedProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateSavedProductSubscription = {
  onCreateSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSavedProductSubscriptionVariables = {
  filter?: ModelSubscriptionSavedProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSavedProductSubscription = {
  onUpdateSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSavedProductSubscriptionVariables = {
  filter?: ModelSubscriptionSavedProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSavedProductSubscription = {
  onDeleteSavedProduct?:  {
    __typename: "SavedProduct",
    user_id: string,
    product_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserFavoriteStoreSubscriptionVariables = {
  filter?: ModelSubscriptionUserFavoriteStoreFilterInput | null,
  owner?: string | null,
};

export type OnCreateUserFavoriteStoreSubscription = {
  onCreateUserFavoriteStore?:  {
    __typename: "UserFavoriteStore",
    user_id: string,
    store_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserFavoriteStoreSubscriptionVariables = {
  filter?: ModelSubscriptionUserFavoriteStoreFilterInput | null,
  owner?: string | null,
};

export type OnUpdateUserFavoriteStoreSubscription = {
  onUpdateUserFavoriteStore?:  {
    __typename: "UserFavoriteStore",
    user_id: string,
    store_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserFavoriteStoreSubscriptionVariables = {
  filter?: ModelSubscriptionUserFavoriteStoreFilterInput | null,
  owner?: string | null,
};

export type OnDeleteUserFavoriteStoreSubscription = {
  onDeleteUserFavoriteStore?:  {
    __typename: "UserFavoriteStore",
    user_id: string,
    store_id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateVisionSearchEntrySubscriptionVariables = {
  filter?: ModelSubscriptionVisionSearchEntryFilterInput | null,
};

export type OnCreateVisionSearchEntrySubscription = {
  onCreateVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVisionSearchEntrySubscriptionVariables = {
  filter?: ModelSubscriptionVisionSearchEntryFilterInput | null,
};

export type OnUpdateVisionSearchEntrySubscription = {
  onUpdateVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVisionSearchEntrySubscriptionVariables = {
  filter?: ModelSubscriptionVisionSearchEntryFilterInput | null,
};

export type OnDeleteVisionSearchEntrySubscription = {
  onDeleteVisionSearchEntry?:  {
    __typename: "VisionSearchEntry",
    id: string,
    user_id: string,
    image_url: string,
    result?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomFilterInput | null,
};

export type OnCreateStylistRoomSubscription = {
  onCreateStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?: Array< string | null > | null,
    userIds?: Array< string | null > | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type OnUpdateStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomFilterInput | null,
};

export type OnUpdateStylistRoomSubscription = {
  onUpdateStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?: Array< string | null > | null,
    userIds?: Array< string | null > | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type OnDeleteStylistRoomSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomFilterInput | null,
};

export type OnDeleteStylistRoomSubscription = {
  onDeleteStylistRoom?:  {
    __typename: "StylistRoom",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    users?: Array< string | null > | null,
    userIds?: Array< string | null > | null,
    entries?:  {
      __typename: "ModelStylistRoomEntryConnection",
      nextToken?: string | null,
    } | null,
    modelBuild?: string | null,
    userStatus?: string | null,
  } | null,
};

export type OnCreateStylistRoomInviteSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomInviteFilterInput | null,
};

export type OnCreateStylistRoomInviteSubscription = {
  onCreateStylistRoomInvite?:  {
    __typename: "StylistRoomInvite",
    id: string,
    stylistRoomId: string,
    completed: boolean,
    completedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStylistRoomInviteSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomInviteFilterInput | null,
};

export type OnUpdateStylistRoomInviteSubscription = {
  onUpdateStylistRoomInvite?:  {
    __typename: "StylistRoomInvite",
    id: string,
    stylistRoomId: string,
    completed: boolean,
    completedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStylistRoomInviteSubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomInviteFilterInput | null,
};

export type OnDeleteStylistRoomInviteSubscription = {
  onDeleteStylistRoomInvite?:  {
    __typename: "StylistRoomInvite",
    id: string,
    stylistRoomId: string,
    completed: boolean,
    completedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStylistRoomEntrySubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomEntryFilterInput | null,
};

export type OnCreateStylistRoomEntrySubscription = {
  onCreateStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStylistRoomEntrySubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomEntryFilterInput | null,
};

export type OnUpdateStylistRoomEntrySubscription = {
  onUpdateStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStylistRoomEntrySubscriptionVariables = {
  filter?: ModelSubscriptionStylistRoomEntryFilterInput | null,
};

export type OnDeleteStylistRoomEntrySubscription = {
  onDeleteStylistRoomEntry?:  {
    __typename: "StylistRoomEntry",
    id: string,
    stylistRoomId: string,
    senderId: string,
    content: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
