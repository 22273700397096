import getAxiosInstance, {AxiosInstance} from '@atorie/core/axios'

import {productsService} from '../products'
import {
  ProductCardFragment,
  ProductsQuery,
  ProductsQueryVariables,
} from '../types/storefront.generated'
import {productByIdQuery} from './product-by-id'
import {productRecommendationsQuery} from './product-recommendations'
import {productsQuery} from './products'
import {productsByIdsQuery} from './products-by-ids'

export class ShopifyService {
  private static instance: ShopifyService
  private axios: AxiosInstance

  public static getInstance(): ShopifyService {
    if (!ShopifyService.instance) {
      ShopifyService.instance = new ShopifyService()
    }

    return ShopifyService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async getProducts(variables: ProductsQueryVariables) {
    const resp = await productsQuery(variables)
    const hasError = !!resp.errors

    if (hasError) {
      throw new Error(resp.errors?.message ?? 'Failed to fetch products')
    }

    const data = resp.data

    if (!data) {
      throw new Error('Failed to fetch products')
    }

    const products = data.products

    if (!products) {
      throw new Error('Failed to fetch products')
    }

    return products
  }

  async getAdminProducts(variables: ProductsQueryVariables) {
    const resp = await productsService.products(variables)

    // TODO: Add proper typing. This is a hack to match types.
    const data = resp as unknown as ProductsQuery

    if (!data) {
      throw new Error('Failed to fetch products')
    }

    const products = data.products

    if (!products) {
      throw new Error('Failed to fetch products')
    }

    return products
  }

  async getProductById(
    id: string,
  ): Promise<ProductCardFragment | undefined | null> {
    const resp = await productByIdQuery(id)

    const hasError = !!resp.errors

    if (hasError) {
      throw new Error(resp.errors?.message ?? 'Failed to fetch product')
    }

    return resp.data?.product
  }

  async getProductsByIds(ids: string[]): Promise<ProductCardFragment[]> {
    const resp = await productsByIdsQuery(ids)

    const hasError = !!resp.errors

    if (hasError) {
      throw new Error(resp.errors?.message ?? 'Failed to fetch product')
    }

    const products = resp.data?.nodes ?? []

    return products as ProductCardFragment[]
  }

  async getProductsByTaobaoId(taobaoId: string): Promise<ProductCardFragment> {
    const resp = await this.axios.get<ProductCardFragment>(
      `/products/taobao-id/${taobaoId}`,
    )

    const hasError = !!resp.request.errors

    if (hasError) {
      throw new Error(
        (resp.request?.message as string) ?? 'Failed to fetch product',
      )
    }

    return resp.data
  }

  async getVendors() {
    const req = await this.axios.get(`/products/vendors`)
    return req.data.data as string[]
  }

  async getProductRecommendations(
    productId: string,
  ): Promise<ProductCardFragment[]> {
    const resp = await productRecommendationsQuery(productId)

    const hasError = !!resp.errors

    if (hasError) {
      throw new Error(resp.errors?.message ?? 'Failed to fetch recommendations')
    }

    return resp.data?.productRecommendations ?? []
  }

  async setFeaturedMedia(productId: string, mediaId: string) {
    const resp = await this.axios.post('/shopify/admin/set-featured-media', {
      product_id: productId,
      media_id: mediaId,
    })

    const hasError = !!resp.request.errors

    if (hasError) {
      throw new Error(
        (resp.request?.message as string) ?? 'Failed to set featured media'
      )
    }

    return resp.data
  }
}

export const shopifyService = ShopifyService.getInstance()
