import {
  productRequestService,
  ProductRequestsQueryParams,
} from '@atorie/api/product-requests'
import {queryOptions, useSuspenseQuery} from '@tanstack/react-query'

export function productRequestOptions(
  queryParams: ProductRequestsQueryParams = {},
) {
  return queryOptions({
    queryKey: ['product-requests', queryParams] as const,
    queryFn: async ({queryKey: [, query]}) => {
      try {
        const rq = await productRequestService.getAll(query)
        return rq.data
      } catch (error) {
        return []
      }
    },
  })
}

export function useProductRequestQuery(
  queryParams: ProductRequestsQueryParams = {},
) {
  console.log('queryParams', queryParams)
  return useSuspenseQuery(productRequestOptions(queryParams))
}

export function productRequestByIdOptions(id: string) {
  return queryOptions({
    queryKey: ['product-requests', id] as const,
    queryFn: ({queryKey: [, id]}) => productRequestService.getById(id),
    select: (data) => data.data,
  })
}

export function useProductRequestByIdQuery(id: string) {
  return useSuspenseQuery(productRequestByIdOptions(id))
}

export default useProductRequestQuery
