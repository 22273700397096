import {getStorefrontApiClient} from '@atorie/core/shopify'

import ProductCardFragment from '../admin/graphql/product-card.fragment'

export function productRecommendationsQuery(productId: string) {
  const shopifyClient = getStorefrontApiClient()
  return shopifyClient.request(
    `#graphql
    query getProductRecommendations($productId: ID!) {
      productRecommendations(productId: $productId) {
        ...ProductCard
      }
    }
    ${ProductCardFragment}
    `,
    {
      variables: {productId},
    },
  )
}
