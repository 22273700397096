import {getStorefrontApiClient} from '@atorie/core/shopify'
import {ClientResponse} from '@shopify/graphql-client'

import ProductCardFragment from '../admin/graphql/product-card.fragment'
import {ProductsByIdsQuery} from '../types/storefront.generated'

export async function productsByIdsQuery(
  ids: string[],
): Promise<ClientResponse<ProductsByIdsQuery>> {
  const shopifyClient = getStorefrontApiClient()

  const response = await shopifyClient.request<ProductsByIdsQuery>(
    `#graphql
    query ProductsByIds ($ids: [ID!]!) {
      nodes(ids: $ids) {
        ... on Product {
          ...ProductCard
        }
      }
    }
    ${ProductCardFragment}
    `,
    {
      variables: {ids},
    },
  )
  return response
}
