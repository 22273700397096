import {uploadData} from 'aws-amplify/storage'
import {useCallback, useState} from 'react'

import {useMutation} from '@tanstack/react-query'

interface UploadMutationParams {
  path: string
  file: File
}

function uploadMutationWithProgress(onProgress: (progress: number) => void) {
  return async function uploadMutationFn({path, file}: UploadMutationParams) {
    const result = await uploadData({
      path,
      data: file,
      options: {
        onProgress: ({transferredBytes, totalBytes}) => {
          if (totalBytes) {
            onProgress((transferredBytes / totalBytes) * 100)
          }
        },
      },
    }).result

    return result
  }
}

export function useUpload() {
  const [progress, setProgress] = useState(0)
  const onProgress = useCallback((progress: number) => {
    setProgress(progress)
  }, [])

  const {mutate, data, ...mutRest} = useMutation({
    mutationFn: uploadMutationWithProgress(onProgress),
    onMutate: () => {
      setProgress(0)
    },
  })

  return {
    result: data,
    progress,
    uploadFile: mutate,
    ...mutRest,
  }
}
