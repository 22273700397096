import { useState, useEffect } from "react";
import { storesService } from "@atorie/api/stores";
import { ShopifyProduct } from "@atorie/storefront/shopify";

export function useStorePaginatedProducts(storeId: string) {
  const [products, setProducts] = useState<ShopifyProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGE_SIZE = 12;

  useEffect(() => {
    fetchInitialProducts();
  }, [storeId]);

  const fetchInitialProducts = async () => {
    try {
      const data = await storesService.findStoreProductsPaginated(
        storeId,
        0,
        PAGE_SIZE
      );
      setProducts(data.products as unknown as ShopifyProduct[]);
      setHasNextPage(data.products.length === PAGE_SIZE);
    } catch (error) {
      console.error("Failed to fetch store products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNextPage = async () => {
    const nextPage = currentPage + 1;
    try {
      const data = await storesService.findStoreProductsPaginated(
        storeId,
        nextPage * PAGE_SIZE,
        PAGE_SIZE
      );

      setProducts((prev) => [
        ...prev,
        ...(data.products as unknown as ShopifyProduct[]),
      ]);
      setHasNextPage(data.products.length === PAGE_SIZE);
      setCurrentPage(nextPage);
    } catch (error) {
      console.error("Failed to fetch next page:", error);
    }
  };

  return {
    products,
    isLoading,
    hasNextPage,
    fetchNextPage,
  };
}
