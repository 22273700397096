import getAxiosInstance, {AxiosInstance} from '@atorie/core/axios'

import {Product} from '../types/storefront.types'

export class RecommendationsService {
  private static instance: RecommendationsService
  private axios: AxiosInstance

  public static getInstance(): RecommendationsService {
    if (!RecommendationsService.instance) {
      RecommendationsService.instance = new RecommendationsService()
    }
    return RecommendationsService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async getRecommendationsForYou(cursor?: string): Promise<Product[]> {
    try {
      const response = await this.axios.get('/recommendations/for-you', {
        params: {cursor},
      })
      return response.data as Product[]
    } catch (error) {
      console.error('Error fetching recommendations:', error)
      throw error
    }
  }

  async getRandomRecommendations(): Promise<Product[]> {
    try {
      const response = await this.axios.get('/recommendations/random')
      return response.data as Product[]
    } catch (error) {
      console.error('Error fetching recommendations:', error)
      throw error
    }
  }
}

export const recommendationsService = RecommendationsService.getInstance()
