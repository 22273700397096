import getAxiosInstance, {AxiosInstance} from '@atorie/core/axios'

export class ProductCompanionService {
  private static instance: ProductCompanionService
  private axios: AxiosInstance

  public static getInstance(): ProductCompanionService {
    if (!ProductCompanionService.instance) {
      ProductCompanionService.instance = new ProductCompanionService()
    }
    return ProductCompanionService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async analyzeProduct(productId: string): Promise<any> {
    try {
      const response = await this.axios.post('/shopify/companion', {
        product_id: productId,
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data
    } catch (error) {
      console.error('Error analyzing product:', error)
      throw error
    }
  }

  async analyzeImageUrl(imageUrl: string): Promise<any> {
    try {
      const response = await this.axios.post(
        '/shopify/companion/analyze-image-url',
        {
          image_url: imageUrl,
        },
      )
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data
    } catch (error) {
      console.error('Error analyzing image URL:', error)
      throw error
    }
  }

  async getProductCompanion(productId: string): Promise<any> {
    console.log('Getting product companion for', productId)
    try {
      const response = await this.axios.get(`/shopify/companion/${productId}`)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data.companion
    } catch (error) {
      console.error('Error getting product companion:', error)
      return null
    }
  }

  async getPublicProductCompanion(productId: string): Promise<any> {
    try {
      const response = await this.axios.get(
        `/shopify/companion/public/${productId}`,
      )
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data.companion
    } catch (error) {
      console.error('Error getting product companion:', error)
      return null
    }
  }

  async getProductCompanionByTaobaoId(taobaoId: string): Promise<any> {
    console.log('Getting product companion for Taobao ID', taobaoId)
    try {
      const response = await this.axios.get(
        `/shopify/companion/taobao/${taobaoId}`,
      )
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data.companion
    } catch (error) {
      console.error('Error getting product companion by Taobao ID:', error)
      return null
    }
  }
}

export const productCompanionService = ProductCompanionService.getInstance()
