import {updateFeaturedImage} from '@atorie/api/admin'
import {useMutation, useQueryClient} from '@tanstack/react-query'

import {featuredImagesQueryOptions} from './use-featured-images'

export function useFeaturedImageBoundingPolyIndexMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn({
      id,
      boundingPolyIndex,
    }: {
      id: string
      boundingPolyIndex: number
    }) {
      return updateFeaturedImage(id, {
        bounding_poly_index: boundingPolyIndex,
      })
    },
    onSuccess({published}) {
      queryClient.invalidateQueries(featuredImagesQueryOptions({published}))
    },
  })
}
