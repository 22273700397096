import {VisionSearchEntry} from '@atorie/api/types'
import {VisionAnalysisResult} from '@atorie/api/vision'
import {visionSearchEntriesService} from '@atorie/api/vision-search-entries'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'

export interface VisionSearchInput {
  imageUrl: string
  userId: string
  result: VisionAnalysisResult
}

export interface VisionSearchMutationOptions
  extends Omit<
    UseMutationOptions<VisionSearchEntry, Error, VisionSearchInput>,
    'mutationFn'
  > {}

export function useVisionSearchEntryMutation({
  onSuccess,
  ...opts
}: VisionSearchMutationOptions = {}) {
  const createVisionSearchMutation = useMutation({
    async mutationFn({imageUrl, userId, result}: VisionSearchInput) {
      // Create the entry after uploading
      return visionSearchEntriesService.createEntry({
        userId,
        imageUrl,
        searchResult: result,
      })
    },
    onSuccess(...args) {
      // Invalidate any queries to refresh the data
      //   if (user?.id) {
      //     queryClient.invalidateQueries(['vision-search-entries', user.id])
      //   }

      onSuccess?.(...args) // Call onSuccess if provided
    },
    ...opts,
  })

  return {
    ...createVisionSearchMutation,
  }
}
