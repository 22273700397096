import {shopifyService} from '@atorie/api/shopify'
import {StorefrontGenerated} from '@atorie/api/types'
import {queryOptions, useQuery} from '@tanstack/react-query'

type UseShopifyProductByTaobaoIdQueryOptions = Omit<
  ReturnType<typeof shopifyProductByTaobaoIdQueryOptions>,
  'queryKey' | 'queryFn' | 'queryHash' | 'queryHashFn' | 'initialData'
>

export function shopifyProductByTaobaoIdQueryOptions(taobaoId: string) {
  return queryOptions({
    queryKey: ['shopify-products-taobao', taobaoId] as const,
    queryFn: async ({queryKey: [_, taobaoId]}) => {
      return await shopifyService.getProductsByTaobaoId(taobaoId)
    },
  })
}

export function useShopifyProductByTaobaoIdQuery(
  taobaoId: string,
  options: Partial<UseShopifyProductByTaobaoIdQueryOptions> = {},
) {
  return useQuery({
    ...shopifyProductByTaobaoIdQueryOptions(taobaoId),
    ...options,
  })
}
