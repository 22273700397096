import {VisionAnalysisResult} from '@atorie/api/vision'
import {visionSearchEntriesService} from '@atorie/api/vision-search-entries'
// Removed subscription as requested
import {queryOptions, useQuery} from '@tanstack/react-query'

export function visionSearchByIdQueryOptions(id: string) {
  return queryOptions({
    queryKey: ['vision-search', id] as const,
    queryFn: async ({queryKey: [, id]}) => {
      const entry = await visionSearchEntriesService.getEntryById(id)
      const resultParse = JSON.parse(
        entry?.result ?? 'null',
      ) as VisionAnalysisResult | null // Updated result type
      return {
        ...entry, // Updated data access
        jsonResult: resultParse,
      }
    },
  })
}

export function useVisionSearchByIdQuery(id: string) {
  const visionSearchQuery = useQuery(visionSearchByIdQueryOptions(id))

  return {
    ...visionSearchQuery,
  }
}
