/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const deleteVisionSearchEntry = /* GraphQL */ `mutation DeleteVisionSearchEntry(
  $input: DeleteVisionSearchEntryInput!
  $condition: ModelVisionSearchEntryConditionInput
) {
  deleteVisionSearchEntry(input: $input, condition: $condition) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisionSearchEntryMutationVariables,
  APITypes.DeleteVisionSearchEntryMutation
>;
export const createStylistRoomInvite = /* GraphQL */ `mutation CreateStylistRoomInvite(
  $input: CreateStylistRoomInviteInput!
  $condition: ModelStylistRoomInviteConditionInput
) {
  createStylistRoomInvite(input: $input, condition: $condition) {
    id
    stylistRoomId
    completed
    completedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStylistRoomInviteMutationVariables,
  APITypes.CreateStylistRoomInviteMutation
>;
export const updateStylistRoomInvite = /* GraphQL */ `mutation UpdateStylistRoomInvite(
  $input: UpdateStylistRoomInviteInput!
  $condition: ModelStylistRoomInviteConditionInput
) {
  updateStylistRoomInvite(input: $input, condition: $condition) {
    id
    stylistRoomId
    completed
    completedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStylistRoomInviteMutationVariables,
  APITypes.UpdateStylistRoomInviteMutation
>;
export const deleteStylistRoomInvite = /* GraphQL */ `mutation DeleteStylistRoomInvite(
  $input: DeleteStylistRoomInviteInput!
  $condition: ModelStylistRoomInviteConditionInput
) {
  deleteStylistRoomInvite(input: $input, condition: $condition) {
    id
    stylistRoomId
    completed
    completedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStylistRoomInviteMutationVariables,
  APITypes.DeleteStylistRoomInviteMutation
>;
export const createCloneTrainingJob = /* GraphQL */ `mutation CreateCloneTrainingJob(
  $input: CreateCloneTrainingJobInput!
  $condition: ModelCloneTrainingJobConditionInput
) {
  createCloneTrainingJob(input: $input, condition: $condition) {
    id
    user_id
    status
    progress
    clone_id
    is_product_clone
    error
    completed_at
    duration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCloneTrainingJobMutationVariables,
  APITypes.CreateCloneTrainingJobMutation
>;
export const deleteCloneTrainingJob = /* GraphQL */ `mutation DeleteCloneTrainingJob(
  $input: DeleteCloneTrainingJobInput!
  $condition: ModelCloneTrainingJobConditionInput
) {
  deleteCloneTrainingJob(input: $input, condition: $condition) {
    id
    user_id
    status
    progress
    clone_id
    is_product_clone
    error
    completed_at
    duration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCloneTrainingJobMutationVariables,
  APITypes.DeleteCloneTrainingJobMutation
>;
export const createSavedProduct = /* GraphQL */ `mutation CreateSavedProduct(
  $input: CreateSavedProductInput!
  $condition: ModelSavedProductConditionInput
) {
  createSavedProduct(input: $input, condition: $condition) {
    user_id
    product_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSavedProductMutationVariables,
  APITypes.CreateSavedProductMutation
>;
export const updateSavedProduct = /* GraphQL */ `mutation UpdateSavedProduct(
  $input: UpdateSavedProductInput!
  $condition: ModelSavedProductConditionInput
) {
  updateSavedProduct(input: $input, condition: $condition) {
    user_id
    product_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSavedProductMutationVariables,
  APITypes.UpdateSavedProductMutation
>;
export const deleteSavedProduct = /* GraphQL */ `mutation DeleteSavedProduct(
  $input: DeleteSavedProductInput!
  $condition: ModelSavedProductConditionInput
) {
  deleteSavedProduct(input: $input, condition: $condition) {
    user_id
    product_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSavedProductMutationVariables,
  APITypes.DeleteSavedProductMutation
>;
export const createUserFavoriteStore = /* GraphQL */ `mutation CreateUserFavoriteStore(
  $input: CreateUserFavoriteStoreInput!
  $condition: ModelUserFavoriteStoreConditionInput
) {
  createUserFavoriteStore(input: $input, condition: $condition) {
    user_id
    store_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserFavoriteStoreMutationVariables,
  APITypes.CreateUserFavoriteStoreMutation
>;
export const updateUserFavoriteStore = /* GraphQL */ `mutation UpdateUserFavoriteStore(
  $input: UpdateUserFavoriteStoreInput!
  $condition: ModelUserFavoriteStoreConditionInput
) {
  updateUserFavoriteStore(input: $input, condition: $condition) {
    user_id
    store_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserFavoriteStoreMutationVariables,
  APITypes.UpdateUserFavoriteStoreMutation
>;
export const deleteUserFavoriteStore = /* GraphQL */ `mutation DeleteUserFavoriteStore(
  $input: DeleteUserFavoriteStoreInput!
  $condition: ModelUserFavoriteStoreConditionInput
) {
  deleteUserFavoriteStore(input: $input, condition: $condition) {
    user_id
    store_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserFavoriteStoreMutationVariables,
  APITypes.DeleteUserFavoriteStoreMutation
>;
export const createVisionSearchEntry = /* GraphQL */ `mutation CreateVisionSearchEntry(
  $input: CreateVisionSearchEntryInput!
  $condition: ModelVisionSearchEntryConditionInput
) {
  createVisionSearchEntry(input: $input, condition: $condition) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisionSearchEntryMutationVariables,
  APITypes.CreateVisionSearchEntryMutation
>;
export const updateVisionSearchEntry = /* GraphQL */ `mutation UpdateVisionSearchEntry(
  $input: UpdateVisionSearchEntryInput!
  $condition: ModelVisionSearchEntryConditionInput
) {
  updateVisionSearchEntry(input: $input, condition: $condition) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisionSearchEntryMutationVariables,
  APITypes.UpdateVisionSearchEntryMutation
>;
export const createStylistRoom = /* GraphQL */ `mutation CreateStylistRoom(
  $input: CreateStylistRoomInput!
  $condition: ModelStylistRoomConditionInput
) {
  createStylistRoom(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    users
    userIds
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStylistRoomMutationVariables,
  APITypes.CreateStylistRoomMutation
>;
export const updateStylistRoom = /* GraphQL */ `mutation UpdateStylistRoom(
  $input: UpdateStylistRoomInput!
  $condition: ModelStylistRoomConditionInput
) {
  updateStylistRoom(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    users
    userIds
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStylistRoomMutationVariables,
  APITypes.UpdateStylistRoomMutation
>;
export const deleteStylistRoom = /* GraphQL */ `mutation DeleteStylistRoom(
  $input: DeleteStylistRoomInput!
  $condition: ModelStylistRoomConditionInput
) {
  deleteStylistRoom(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    users
    userIds
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStylistRoomMutationVariables,
  APITypes.DeleteStylistRoomMutation
>;
export const createStylistRoomEntry = /* GraphQL */ `mutation CreateStylistRoomEntry(
  $input: CreateStylistRoomEntryInput!
  $condition: ModelStylistRoomEntryConditionInput
) {
  createStylistRoomEntry(input: $input, condition: $condition) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStylistRoomEntryMutationVariables,
  APITypes.CreateStylistRoomEntryMutation
>;
export const updateStylistRoomEntry = /* GraphQL */ `mutation UpdateStylistRoomEntry(
  $input: UpdateStylistRoomEntryInput!
  $condition: ModelStylistRoomEntryConditionInput
) {
  updateStylistRoomEntry(input: $input, condition: $condition) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStylistRoomEntryMutationVariables,
  APITypes.UpdateStylistRoomEntryMutation
>;
export const deleteStylistRoomEntry = /* GraphQL */ `mutation DeleteStylistRoomEntry(
  $input: DeleteStylistRoomEntryInput!
  $condition: ModelStylistRoomEntryConditionInput
) {
  deleteStylistRoomEntry(input: $input, condition: $condition) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStylistRoomEntryMutationVariables,
  APITypes.DeleteStylistRoomEntryMutation
>;
export const updateCloneTrainingJob = /* GraphQL */ `mutation UpdateCloneTrainingJob(
  $input: UpdateCloneTrainingJobInput!
  $condition: ModelCloneTrainingJobConditionInput
) {
  updateCloneTrainingJob(input: $input, condition: $condition) {
    id
    user_id
    status
    progress
    clone_id
    is_product_clone
    error
    completed_at
    duration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCloneTrainingJobMutationVariables,
  APITypes.UpdateCloneTrainingJobMutation
>;
