import {deleteFeaturedImage} from '@atorie/api/admin'
import {useMutation, useQueryClient} from '@tanstack/react-query'

import {featuredImagesQueryOptions} from './use-featured-images'

export function useDeleteFeaturedImageMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn({id}: {id: string}) {
      return await deleteFeaturedImage(id)
    },
    onSuccess() {
      queryClient.invalidateQueries(
        featuredImagesQueryOptions({published: false}),
      )

      queryClient.invalidateQueries(
        featuredImagesQueryOptions({published: true}),
      )
    },
  })
}
