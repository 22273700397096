import {getAxiosInstance, type AxiosInstance} from '@atorie/core/axios'

interface BoundingBox {
  x: number
  y: number
  width: number
  height: number
}

export interface ModelInfo {
  ethnicity: string
  gender: string
  height: string
  weight: string
  size: string
  measurements: any[]
  face_bounding_box: BoundingBox
  face_image_url?: string
}

export interface ImageAnalysis {
  id: string
  uploaded_image_id: string
  product_data: {
    product: {
      weight: string
      materials: string[]
      dimensions: string[]
      size_chart: string[]
      description: string
      raw_text_in_english: string
    }
  }
  model_data: ModelInfo[]
  face_bounding_box: BoundingBox | null
  original_image_url: string
  edit_log: any
  latest_edit_image_url: string | null
  created_at: string
  updated_at: string
  text_blocks?: Array<{
    bounding_box: BoundingBox
    text: string
  }>
}

export interface EditLog {
  initial_image_url: string
  edited_image_url: string
  reason: string
  initial_image_analysis_id: string
  timestamp: string
  updated_by: string
}
export interface UploadedImage {
  id: string
  image_url: string
  is_published: boolean
  edit_log?: EditLog[]
  metadata?: Record<string, any>
  image_analysis_id?: string
  image_analysis?: ImageAnalysis
  shopify_product_companion_id?: string
  created_at: Date
  updated_at: Date
}

export class UploadedImagesService {
  private static instance: UploadedImagesService
  private axios: AxiosInstance

  public static getInstance(): UploadedImagesService {
    if (!UploadedImagesService.instance) {
      UploadedImagesService.instance = new UploadedImagesService()
    }

    return UploadedImagesService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async createFromUrl(imageUrl: string, shopifyProductCompanionId?: string) {
    const response = await this.axios.post('/uploaded-images', {
      imageUrl,
      shopifyProductCompanionId,
    })
    return response.data
  }

  async findByCompanionId(companionId: string) {
    const response = await this.axios.get(
      `/uploaded-images/companion/${companionId}`,
    )
    return response.data
  }

  async publish(id: string, productId: string) {
    const response = await this.axios.post(`/uploaded-images/${id}/publish`, {
      productId,
    })
    return response.data
  }

  async unpublish(id: string, productId: string) {
    const response = await this.axios.post(`/uploaded-images/${id}/unpublish`, {
      productId,
    })
    return response.data
  }

  async editAndAnalyze(id: string, newImageUrl: string, message: string) {
    const response = await this.axios.put(`/uploaded-images/${id}/edit`, {
      newImageUrl,
      message,
    })
    return response.data
  }
}

export const uploadedImagesService = UploadedImagesService.getInstance()
