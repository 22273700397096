import {getAxiosInstance} from '@atorie/core/axios'

import {FeaturedImage, ShopifyProductMatch} from './types'

export * from './types'

export interface CreateFeaturedImageInput {
  name?: string
  description?: string
  image_url: string
  visual_search_request_id?: string
  visual_search_results?: Record<string, any>
  shopify_product_match_id: string
}

/**
 * Creates a featured image.
 * @param variables - The input data for creating the featured image.
 * @returns A promise that resolves to the created featured image.
 */
export async function createFeaturedImage(variables: CreateFeaturedImageInput): Promise<FeaturedImage> {
  const axios = getAxiosInstance()

  const response = await axios.post<FeaturedImage>(
    '/products/shopify-match/featured-image',
    variables,
  )

  return response.data
}

export interface CreateShopifyMatchInput {
  image_url: string
  price?: number | null
  name?: string | null
  brand_name?: string | null
  description?: string | null
  shopify_product_id: string
  shopify_variant_id: string
  metadata?: Record<string, unknown> | null
}

/**
 * Creates a Shopify match.
 * @param shopifyMatch - The input data for creating the Shopify match.
 * @returns A promise that resolves to the created Shopify product match.
 */
export async function createShopifyMatch(
  shopifyMatch: CreateShopifyMatchInput,
): Promise<ShopifyProductMatch> {
  const axios = getAxiosInstance()

  const response = await axios.post<ShopifyProductMatch>(
    '/products/shopify-match',
    shopifyMatch,
  )

  return response.data
}

/**
 * Deletes a featured image.
 * @param id - The ID of the featured image to delete.
 * @returns A promise that resolves when the featured image has been deleted.
 */
export async function deleteFeaturedImage(id: string): Promise<void> {
  const axios = getAxiosInstance()
  return axios.delete(`products/shopify-match/featured-images/${id}`)
}

/**
 * Deletes a Shopify match.
 * @param id - The ID of the Shopify match to delete.
 * @returns A promise that resolves when the Shopify match has been deleted.
 */
export async function deleteShopifyMatch(id: string) {
  const axios = getAxiosInstance()

  return axios.delete(`/products/shopify-match/${id}`)
}



export interface FeaturedImageFilter {
  published?: boolean
}

/**
 * Gets featured images.
 * @param filter - The filter for the featured images.
 * @returns A promise that resolves to an array of featured images.
 */
export async function getFeaturedImages({
  published,
}: FeaturedImageFilter = {}): Promise<FeaturedImage[]> {
  const axios = getAxiosInstance()

  const response = await axios.get<FeaturedImage[]>(
    '/products/shopify-match/featured-images',
    {
      params: {
        published,
    },
  })

  return response.data
}



export interface GetShopifyProductMatchParams {
  shopify_product_id: string
  shopify_variant_id: string
}

/**
 * Gets a Shopify product match.
 * @param params - The parameters for getting the Shopify product match.
 * @returns A promise that resolves to the Shopify product match.
 */
export async function getShopifyProductMatch({
  shopify_product_id,
  shopify_variant_id,
}: GetShopifyProductMatchParams) {
  const axios = getAxiosInstance()

  const response = await axios.get<ShopifyProductMatch>(
    `/products/shopify-match/${shopify_product_id}/variant/${shopify_variant_id}`,
  )

  return response.data
}

export interface UpdateFeaturedImageInput {
  name?: string
  description?: string
  image_url?: string
  published?: boolean
  bounding_poly_index?: number
  visual_search_results?: Record<string, unknown> | null
}


/**
 * Updates a featured image.
 * @param id - The ID of the featured image to update.
 * @param input - The input data for updating the featured image.
 * @returns A promise that resolves to the updated featured image.
 */
export async function updateFeaturedImage(
  id: string,
  input: UpdateFeaturedImageInput,
): Promise<FeaturedImage> {
  const axios = getAxiosInstance()

  const response = await axios.patch<FeaturedImage>(
    `products/shopify-match/featured-images/${id}`,
    input,
  )

  return response.data
}

export interface UpdateShopifyMatchInput {
  id: string
  image_url?: string | null
  price?: number | null
  name?: string | null
  brand_name?: string | null
  description?: string | null
  metadata?: Record<string, unknown> | null
}

/**
 * Updates a Shopify match.
 * @param shopifyMatch - The input data for updating the Shopify match.
 * @returns A promise that resolves to the updated Shopify product match.
 */
export async function updateShopifyMatch({
  id,
  ...shopifyMatch
}: UpdateShopifyMatchInput) {
  const axios = getAxiosInstance()

  const response = await axios.put<ShopifyProductMatch>(
    `/products/shopify-match/${id}`,
    shopifyMatch,
  )

  return response.data
}
