import { useQuery, useInfiniteQuery, queryOptions,infiniteQueryOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthUser } from "./use-auth-user";
import { createUserFavoriteStore, deleteUserFavoriteStore, getUserFavoriteStore, getUserFavoriteStores } from "@atorie/api/user-favorite-stores";
import { storesService } from '@atorie/api/stores'


export function userFavoriteStoresQueryOptions(userId: string) {
  return queryOptions({
    queryKey: ['stores','favorites', userId] as const,
    async queryFn({queryKey}) {
      const [, , userId] = queryKey
      const data = await getUserFavoriteStores({user_id: userId})

      const storesIds = data?.listUserFavoriteStores?.items.reduce((acc, item) => {
        if (item?.store_id) {
          acc.push(item.store_id)
        }

        return acc
      }, [] as string[]) ?? []

      const stores = await storesService.findByIds(storesIds)

      return {stores, nextToken: data?.listUserFavoriteStores?.nextToken}
    },
  })
}

export function userFavoriteStoresInfinityQueryOptions(userId: string) {
  return infiniteQueryOptions({
    queryKey: ['user', userId, 'followed-stores'] as const,
    initialData: undefined,
    async queryFn({queryKey, pageParam}) {
      const [, userId] = queryKey
      const favoritesStore = await getUserFavoriteStores({ user_id: userId, nextToken: pageParam })
      
      const storesIds = favoritesStore?.listUserFavoriteStores?.items.reduce((acc, item) => {
        if (item?.store_id) {
          acc.push(item.store_id)
        }

        return acc
      }, [] as string[]) ?? []

      const stores = await storesService.findByIds(storesIds)

      return {stores, nextToken: favoritesStore?.listUserFavoriteStores?.nextToken}
    },
    getNextPageParam: (lastPage) => {
      const hasNext = lastPage.nextToken != null
      if (!hasNext) return

      return lastPage.nextToken
    },
    select(data) {
      return data.pages.flatMap((page) => page.stores)
    },
    initialPageParam: undefined as string | undefined,
  })
}


export function useUserFavoriteStores() {
  const { user } = useAuthUser()
  
  return useInfiniteQuery({
    ...userFavoriteStoresInfinityQueryOptions(user?.id ?? ''),
    enabled: !!user?.id,
  })
}

export function userFavoriteStoreByIdQueryOptions(userId: string, storeId: string) {
  return queryOptions({
    queryKey: ['stores', 'favorites', userId, storeId] as const,
    async queryFn({queryKey}) {
      const [, , userId, storeId] = queryKey
      const data = await getUserFavoriteStore(userId, storeId)
    
      if (!data?.getUserFavoriteStore) { 
        return null
      }
      
      return data?.getUserFavoriteStore
    },
  })
}

export function useUserFavoriteStoreById(storeId: string) { 
  const { user } = useAuthUser()

  return useQuery({
    ...userFavoriteStoreByIdQueryOptions(user?.id ?? '', storeId),
    enabled: !!user?.id,
  })
}

export function useIsStoreInUserFavoriteStores(storeId: string) {
  const { data, ...rest } = useUserFavoriteStoreById(storeId)

  return {
    data: !!data,
    ...rest,
  }
}


export function useToggleUserFavoriteStoreMutation() {
  const { user } = useAuthUser()
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn({storeId}: {storeId: string}) {
      if (!user?.id) {
        throw new Error('User not authenticated')
      }

      let storeInUserFavoriteStores = queryClient.getQueryData(userFavoriteStoreByIdQueryOptions(user.id, storeId).queryKey)

      if (storeInUserFavoriteStores === undefined) {
        try {
          const data = await getUserFavoriteStore(user.id, storeId)
          storeInUserFavoriteStores = data?.getUserFavoriteStore
        } catch {
          storeInUserFavoriteStores = null
        }
      }

      if (storeInUserFavoriteStores) {
        return await deleteUserFavoriteStore(user.id, storeId)
      } else {
        return await createUserFavoriteStore(user.id, storeId)
      }
    },
    onSettled(_, __, {storeId}) {
      queryClient.invalidateQueries({
        queryKey: userFavoriteStoresQueryOptions(user?.id ?? '').queryKey,
      })

      queryClient.invalidateQueries({
        queryKey: userFavoriteStoresInfinityQueryOptions(user?.id ?? '').queryKey,
      })

      queryClient.invalidateQueries({
        queryKey: userFavoriteStoreByIdQueryOptions(user?.id ?? '', storeId).queryKey,
      })
    },
  })
 }
