import {generateClient} from 'aws-amplify/api'
import {useEffect, useMemo} from 'react'

import {onUpdateVisionSearchEntry} from '@atorie/api/graphql'
import {VisionSearchEntry} from '@atorie/api/types'
import {visionSearchEntriesService} from '@atorie/api/vision-search-entries'
import {
  infiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {useAuthUser} from './use-auth-user'

interface VisionSearchHistoryInfiniteQueryOption {
  userId?: string
}

export function visionSearchHistoryInfiniteQueryOption({
  userId,
}: VisionSearchHistoryInfiniteQueryOption) {
  return infiniteQueryOptions({
    queryKey: ['user', userId, 'vision-search-history', 'entries'] as const,
    queryFn: async ({pageParam}) => {
      if (!userId) {
        return Promise.resolve({
          items: [],
          nextToken: undefined,
        })
      }
      const visionSearchEntries =
        await visionSearchEntriesService.getUserVisionSearchEntries({
          userId: userId,
          limit: 30,
          nextToken: pageParam.nextToken,
        })

      return {
        items: visionSearchEntries.entries.map(({result, ...item}) => {
          const newItem: typeof item & {
            result: VisionSearchEntry | null
          } = {...item, result: null}
          try {
            newItem.result = JSON.parse(result ?? 'null')
          } catch (e) {
            newItem.result = null
          }
          return newItem
        }),

        nextToken: visionSearchEntries.nextToken,
      }
    },
    getNextPageParam(lastPage) {
      if (!lastPage.nextToken) {
        return
      }

      return {
        nextToken: lastPage.nextToken,
      }
    },
    select(data) {
      return data
    },
    initialData: undefined,
    initialPageParam: {nextToken: undefined} as {nextToken?: string},
  })
}

export function useVisionSearchHistoryInfiniteQuery() {
  const queryClient = useQueryClient()
  const graphqlClient = useMemo(() => generateClient(), [])
  const {user} = useAuthUser()

  useEffect(() => {
    if (!user?.id) return
    const onUpdateVisionSearchEntries = graphqlClient.graphql({
      query: onUpdateVisionSearchEntry,
      variables: {
        filter: {
          user_id: {eq: user.id},
        },
      },
    })

    const subscription = onUpdateVisionSearchEntries.subscribe({
      next(value) {
        const id = value.data.onUpdateVisionSearchEntry.id

        queryClient.invalidateQueries(
          visionSearchHistoryInfiniteQueryOption({userId: user.id}),
        )
      },
    })

    return () => subscription.unsubscribe()
  }, [graphqlClient, queryClient, user?.id])

  return useInfiniteQuery(
    visionSearchHistoryInfiniteQueryOption({userId: user?.id}),
  )
}
