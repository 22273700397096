import {getStorefrontApiClient} from '@atorie/core/shopify'

import ProductCardFragment from '../admin/graphql/product-card.fragment'

export function productByIdQuery(id: string) {
  const shopifyClient = getStorefrontApiClient()
  return shopifyClient.request(
    `#graphql
    query ProductById($id: ID!) {
      product(id: $id) {
        ... on Product {
          ...ProductCard
        }
      }
    }
    ${ProductCardFragment}
    `,
    {
      variables: {id},
    },
  )
}
