import {shopifyService} from '@atorie/api/shopify'
import {StorefrontGenerated} from '@atorie/api/types'
import {queryOptions, useQuery, UseQueryResult} from '@tanstack/react-query'

type UseShopifyProductByIdQueryOptions = Omit<
  ReturnType<typeof shopifyProductByIdQueryOptions>,
  'queryKey' | 'queryFn' | 'queryHash' | 'queryHashFn' | 'initialData'
>

export function shopifyProductByIdQueryOptions(id: string) {
  return queryOptions({
    queryKey: ['shopify-products', id] as const,
    queryFn: async ({ queryKey: [_, id] }) => {
      return await shopifyService.getProductById(id)
    },
  })
}

export function useShopifyProductByIdQuery(
  id: string,
  options: Partial<UseShopifyProductByIdQueryOptions> = {},
) {
  return useQuery({...shopifyProductByIdQueryOptions(id), ...options})
}
