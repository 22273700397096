import {productsService} from '@atorie/api/products'
import {StorefrontGenerated} from '@atorie/api/types'
import {queryOptions, useQuery} from '@tanstack/react-query'

type UseShopifyProductByIdQueryOptions = Omit<
  ReturnType<typeof shopifyAdminProductByIdQueryOptions>,
  'queryKey' | 'queryFn' | 'queryHash' | 'queryHashFn' | 'initialData'
>

export function shopifyAdminProductByIdQueryOptions(id: string) {
  return queryOptions({
    queryKey: ['shopify-admin-products', id] as const,
    queryFn: async ({queryKey: [_, id]}) => {
      return await productsService.getById(id)
    },
  })
}

export function useShopifyAdminProductByIdQuery(
  id: string,
  options: Partial<UseShopifyProductByIdQueryOptions> = {},
) {
  return useQuery({...shopifyAdminProductByIdQueryOptions(id), ...options})
}
