import {
  createStorefrontApiClient,
  type StorefrontApiClient,
} from "@shopify/storefront-api-client";

function makeStorefrontApiClient() {
  return createStorefrontApiClient({
    storeDomain: `https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}`,
    // publicStorefrontToken: '984d84766bd5d09e00f8d6a56805ce9c',
    // storefrontApiVersion: '2023-01',
    apiVersion: "2024-07",
    publicAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  });
}

let browserStorefrontApiClient: StorefrontApiClient | undefined = undefined;

export function getStorefrontApiClient() {
  if (typeof window === "undefined") {
    // Server: always make a new client
    return makeStorefrontApiClient();
  }

  if (!browserStorefrontApiClient) {
    browserStorefrontApiClient = makeStorefrontApiClient();
  }

  return browserStorefrontApiClient;
}
