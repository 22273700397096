import {useContext} from 'react'

import {SearchContext} from './providers'

export function useSearchValue() {
  const ctx = useContext(SearchContext)

  if (!ctx) {
    throw new Error('useSearchValue must be used within a SearchProvider')
  }

  return ctx.searchValue
}

export function useSearchInput() {
  const ctx = useContext(SearchContext)

  if (!ctx) {
    throw new Error('useSearchInput must be used within a SearchProvider')
  }

  return [ctx.value, ctx.setSearchValue] as const
}
