import {defineConfig} from 'cva'
import {extendTailwindMerge} from 'tailwind-merge'

const twMerge = extendTailwindMerge({})

export type {VariantProps} from 'cva'

export const {cva, cx, compose} = defineConfig({
  hooks: {
    onComplete: (className) => twMerge(className),
  },
})
