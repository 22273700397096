import {
  getShopifyProductMatch,
  type GetShopifyProductMatchParams,
} from '@atorie/api/admin'
import {queryOptions, useQuery} from '@tanstack/react-query'

export function shopifyMatchQueryOptions(params: GetShopifyProductMatchParams) {
  return queryOptions({
    queryKey: ['shopify-match', params] as const,
    queryFn: async ({queryKey: [_, params]}) => {
      return getShopifyProductMatch(params)
    },
  })
}

export function useShopifyMatchQuery(params: GetShopifyProductMatchParams) {
  return useQuery(shopifyMatchQueryOptions(params))
}
