"use client";

import { useRef, useEffect } from "react";

import { recommendationsService } from "@atorie/api/recommendations";
import { StorefrontGenerated } from "@atorie/api/types";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { shopifyService } from "@atorie/api/shopify";
import { useAuthUser } from "@atorie/hooks";
import { userService } from "@atorie/api/users";

// At the top of the file, add this type assertion
type SafeProduct = StorefrontGenerated.ProductCardFragment;

// Home feed recommendations
export function useRandomRecommendationsQuery() {
  const seenProducts = useRef(new Map<string, boolean>()).current;

  useEffect(() => {
    return () => seenProducts.clear();
  }, []);

  return useInfiniteQuery({
    queryKey: ["home-recommendations"],
    queryFn: async () => {
      const recommendations =
        await recommendationsService.getRandomRecommendations();

      // Filter out duplicates from the raw recommendations first
      const uniqueProducts = recommendations.filter(
        (product, index) =>
          recommendations.findIndex((p) => p.id === product.id) === index
      );

      const newProducts = uniqueProducts
        .filter((product) => !seenProducts.has(product.id))
        .map(
          (product) =>
            product as unknown as StorefrontGenerated.ProductCardFragment
        );

      newProducts.forEach((product) => {
        seenProducts.set(product.id, true);
      });

      return {
        nodes: newProducts,
        pageInfo: {
          hasNextPage: newProducts.length > 0,
          hasPreviousPage: false,
        },
      };
    },
    initialPageParam: 0,
    getNextPageParam: (_, allPages) => allPages.length,
  });
}

// Modal recommendations with preloading and mixing of random and targeted recommendations
export function useModalRecommendationsQuery(
  initialProduct?: StorefrontGenerated.ProductCardFragment
) {
  const seenProducts = useRef(new Map<string, boolean>()).current;
  const preloadedRandoms = useRef<StorefrontGenerated.ProductCardFragment[]>(
    []
  );

  // Preload random products
  useEffect(() => {
    const preloadRandomProducts = async () => {
      const recommendations =
        await recommendationsService.getRandomRecommendations();
      preloadedRandoms.current = recommendations
        .filter(
          (product, index) =>
            recommendations.findIndex((p) => p.id === product.id) === index
        )
        .map(
          (product) =>
            product as unknown as StorefrontGenerated.ProductCardFragment
        );
    };

    preloadRandomProducts().catch(console.error);
  }, []);

  useEffect(() => {
    seenProducts.clear();
    if (initialProduct) {
      seenProducts.set(initialProduct.id, true);
    }
  }, [initialProduct?.id]);

  return useInfiniteQuery({
    queryKey: ["modal-recommendations", initialProduct?.id],
    queryFn: async ({ pageParam }) => {
      if (pageParam === 0) {
        const initialNodes = initialProduct ? [initialProduct] : [];
        const preloadedProducts = preloadedRandoms.current
          .filter((product) => !seenProducts.has(product.id))
          .slice(0, 10);

        preloadedProducts.forEach((product) => {
          seenProducts.set(product.id, true);
        });

        return {
          nodes: [...initialNodes, ...preloadedProducts],
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: false,
          },
        };
      }

      // For subsequent pages, mix random and targeted recommendations
      const [randomProducts, relatedProducts] = await Promise.all([
        recommendationsService.getRandomRecommendations(),
        initialProduct?.id
          ? shopifyService.getProductRecommendations(initialProduct.id)
          : Promise.resolve([]),
      ]);

      // Create a Set of seen product IDs for O(1) lookup
      const seenSet = new Set(seenProducts.keys());

      // Filter and map in a single pass for each array
      const uniqueRandoms = randomProducts
        .filter(
          (product, index, self) =>
            !seenSet.has(product.id) &&
            self.findIndex((p) => p.id === product.id) === index
        )
        .map(
          (product) =>
            product as unknown as StorefrontGenerated.ProductCardFragment
        );

      const uniqueRelated = relatedProducts
        .filter(
          (product, index, self) =>
            !seenSet.has(product.id) &&
            self.findIndex((p) => p.id === product.id) === index
        )
        .map(
          (product) =>
            product as unknown as StorefrontGenerated.ProductCardFragment
        );

      // Mix products using a more type-safe approach
      const mixedProducts: StorefrontGenerated.ProductCardFragment[] = [];
      const maxLength = Math.max(uniqueRandoms.length, uniqueRelated.length);

      for (let i = 0; i < maxLength; i++) {
        const randomProduct = uniqueRandoms[i];
        const relatedProduct = uniqueRelated[i];

        if (randomProduct) {
          mixedProducts.push(randomProduct);
          seenProducts.set(randomProduct.id, true);
        }
        if (relatedProduct) {
          mixedProducts.push(relatedProduct);
          seenProducts.set(relatedProduct.id, true);
        }
      }

      return {
        nodes: mixedProducts,
        pageInfo: {
          hasNextPage: mixedProducts.length > 0,
          hasPreviousPage: pageParam > 0,
        },
      };
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.pageInfo.hasNextPage ? allPages.length : undefined,
  });
}

// Mixed recommendations for related products section
export function useRelatedProductsFeedQuery(
  productId: string,
  initialProducts: StorefrontGenerated.ProductCardFragment[]
) {
  const seenProducts = useRef(new Map<string, boolean>()).current;

  useEffect(() => {
    seenProducts.clear();
    initialProducts.forEach((product) => {
      seenProducts.set(product.id, true);
    });
  }, [productId]);

  return useInfiniteQuery({
    queryKey: ["related-products-feed", productId],
    queryFn: async ({ pageParam }) => {
      if (pageParam === 0 && initialProducts.length > 0) {
        return {
          nodes: initialProducts,
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: false,
          },
        };
      }

      // For subsequent pages, mix random and related recommendations
      const [randomProducts, relatedProducts] = await Promise.all([
        recommendationsService.getRandomRecommendations(),
        shopifyService.getProductRecommendations(productId),
      ]);

      // Filter out seen products and duplicates
      const uniqueProducts = [...randomProducts, ...relatedProducts]
        .filter(
          (product, index, self) =>
            !seenProducts.has(product.id) &&
            self.findIndex((p) => p.id === product.id) === index
        )
        .map(
          (product) =>
            product as unknown as StorefrontGenerated.ProductCardFragment
        );

      // Mark new products as seen
      uniqueProducts.forEach((product) => {
        seenProducts.set(product.id, true);
      });

      return {
        nodes: uniqueProducts,
        pageInfo: {
          hasNextPage: uniqueProducts.length > 0,
          hasPreviousPage: false,
        },
      };
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) =>
      lastPage.pageInfo.hasNextPage ? 1 : undefined,
  });
}

// Add this new hook for the home feed with mixed recommendations
export function useHomeFeedRecommendationsQuery() {
  const seenProducts = useRef(new Map<string, boolean>()).current;
  const { user, isLoading: isUserLoading } = useAuthUser();
  const pendingRecommendations = useRef<string[]>([]);
  const queryClient = useQueryClient();

  // Initialize with user's latest viewed products
  useEffect(() => {
    if (!isUserLoading && user?.latest_products_viewed) {
      pendingRecommendations.current = user.latest_products_viewed.slice(-3);
    }
  }, [user?.latest_products_viewed, isUserLoading]);

  const updateRecentlyClicked = async (productId: string) => {
    try {
      // Update pending recommendations
      pendingRecommendations.current = [
        productId,
        ...pendingRecommendations.current.filter((id) => id !== productId),
      ].slice(0, 3);

      // Update backend without waiting
      userService.viewProduct(productId).catch((error) => {
        console.error("Failed to update recently viewed products:", error);
      });

      // Fetch new recommendations and update query data
      const newRecommendations =
        await shopifyService.getProductRecommendations(productId);

      // Get current query data
      const currentData = queryClient.getQueryData<{
        pages: Array<{
          nodes: StorefrontGenerated.ProductCardFragment[];
          pageInfo: { hasNextPage: boolean; hasPreviousPage: boolean };
        }>;
      }>(["home-recommendations"]);

      if (currentData?.pages) {
        // Check for pages existence
        // Add new recommendations to the latest page
        queryClient.setQueryData(["home-recommendations"], {
          ...currentData,
          pages: currentData.pages.map((page, index) => {
            if (index === currentData.pages.length - 1) {
              return {
                ...page,
                nodes: [
                  ...page.nodes,
                  ...newRecommendations
                    .filter((p) => !seenProducts.has(p.id))
                    .map(
                      (p) =>
                        p as unknown as StorefrontGenerated.ProductCardFragment
                    ),
                ],
              };
            }
            return page;
          }),
        });
      }
    } catch (error) {
      console.error("Failed to update recently viewed products:", error);
    }
  };

  return {
    query: useInfiniteQuery({
      queryKey: ["home-recommendations"],
      enabled: !isUserLoading,
      staleTime: 5 * 60 * 1000,
      gcTime: 30 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      queryFn: async ({ pageParam }) => {
        try {
          // Explicitly type the arrays
          const [randomProducts, ...relatedProducts] = await Promise.all([
            recommendationsService.getRandomRecommendations().then(products =>
              products.map(p => p as StorefrontGenerated.ProductCardFragment)
            ).catch(() => []),
            ...pendingRecommendations.current.map((id) =>
              shopifyService.getProductRecommendations(id).then(products =>
                products.map(p => p as StorefrontGenerated.ProductCardFragment)
              ).catch(() => [])
            ),
          ]);

          const newRandoms = randomProducts.filter(
            (p) => !seenProducts.has(p.id)
          );
          const newRelated = relatedProducts
            .flat()
            .filter((p) => !seenProducts.has(p.id));

          // Explicitly type the mixed products array
          const mixedProducts: StorefrontGenerated.ProductCardFragment[] = [];
          let randomIndex = 0;
          let relatedIndex = 0;

          while (
            randomIndex < newRandoms.length ||
            relatedIndex < newRelated.length
          ) {
            for (let i = 0; i < 2 && randomIndex < newRandoms.length; i++) {
              const product = newRandoms[randomIndex];
              if (product) {
                mixedProducts.push(product);
              }
              randomIndex++;
            }

            if (relatedIndex < newRelated.length) {
              const product = newRelated[relatedIndex] as SafeProduct;
              mixedProducts.push(product);
              relatedIndex++;
            }
            else if (randomIndex < newRandoms.length) {
              const product = newRandoms[randomIndex];
              if (product) {
                mixedProducts.push(product);
              }
              randomIndex++;
            }
          }

          mixedProducts.forEach((product) => {
            if (product) {
              seenProducts.set(product.id, true);
            }
          });

          return {
            nodes: mixedProducts,
            pageInfo: {
              hasNextPage: newRandoms.length > 0 || newRelated.length > 0,
              hasPreviousPage: false,
            },
          };
        } catch (error) {
          console.error("Failed to fetch recommendations:", error);
          return {
            nodes: [],
            pageInfo: { hasNextPage: false, hasPreviousPage: false },
          };
        }
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.pageInfo.hasNextPage ? allPages.length : undefined,
    }),
    updateRecentlyClicked,
  };
}
