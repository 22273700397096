/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateCloneTrainingJob = /* GraphQL */ `subscription OnCreateCloneTrainingJob(
  $filter: ModelSubscriptionCloneTrainingJobFilterInput
  $owner: String
) {
  onCreateCloneTrainingJob(filter: $filter, owner: $owner) {
    id
    user_id
    status
    progress
    clone_id
    is_product_clone
    error
    completed_at
    duration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCloneTrainingJobSubscriptionVariables,
  APITypes.OnCreateCloneTrainingJobSubscription
>;
export const onUpdateCloneTrainingJob = /* GraphQL */ `subscription OnUpdateCloneTrainingJob(
  $filter: ModelSubscriptionCloneTrainingJobFilterInput
  $owner: String
) {
  onUpdateCloneTrainingJob(filter: $filter, owner: $owner) {
    id
    user_id
    status
    progress
    clone_id
    is_product_clone
    error
    completed_at
    duration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCloneTrainingJobSubscriptionVariables,
  APITypes.OnUpdateCloneTrainingJobSubscription
>;
export const onDeleteCloneTrainingJob = /* GraphQL */ `subscription OnDeleteCloneTrainingJob(
  $filter: ModelSubscriptionCloneTrainingJobFilterInput
  $owner: String
) {
  onDeleteCloneTrainingJob(filter: $filter, owner: $owner) {
    id
    user_id
    status
    progress
    clone_id
    is_product_clone
    error
    completed_at
    duration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCloneTrainingJobSubscriptionVariables,
  APITypes.OnDeleteCloneTrainingJobSubscription
>;
export const onCreateSavedProduct = /* GraphQL */ `subscription OnCreateSavedProduct(
  $filter: ModelSubscriptionSavedProductFilterInput
  $owner: String
) {
  onCreateSavedProduct(filter: $filter, owner: $owner) {
    user_id
    product_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSavedProductSubscriptionVariables,
  APITypes.OnCreateSavedProductSubscription
>;
export const onUpdateSavedProduct = /* GraphQL */ `subscription OnUpdateSavedProduct(
  $filter: ModelSubscriptionSavedProductFilterInput
  $owner: String
) {
  onUpdateSavedProduct(filter: $filter, owner: $owner) {
    user_id
    product_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSavedProductSubscriptionVariables,
  APITypes.OnUpdateSavedProductSubscription
>;
export const onDeleteSavedProduct = /* GraphQL */ `subscription OnDeleteSavedProduct(
  $filter: ModelSubscriptionSavedProductFilterInput
  $owner: String
) {
  onDeleteSavedProduct(filter: $filter, owner: $owner) {
    user_id
    product_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSavedProductSubscriptionVariables,
  APITypes.OnDeleteSavedProductSubscription
>;
export const onCreateUserFavoriteStore = /* GraphQL */ `subscription OnCreateUserFavoriteStore(
  $filter: ModelSubscriptionUserFavoriteStoreFilterInput
  $owner: String
) {
  onCreateUserFavoriteStore(filter: $filter, owner: $owner) {
    user_id
    store_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserFavoriteStoreSubscriptionVariables,
  APITypes.OnCreateUserFavoriteStoreSubscription
>;
export const onUpdateUserFavoriteStore = /* GraphQL */ `subscription OnUpdateUserFavoriteStore(
  $filter: ModelSubscriptionUserFavoriteStoreFilterInput
  $owner: String
) {
  onUpdateUserFavoriteStore(filter: $filter, owner: $owner) {
    user_id
    store_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserFavoriteStoreSubscriptionVariables,
  APITypes.OnUpdateUserFavoriteStoreSubscription
>;
export const onDeleteUserFavoriteStore = /* GraphQL */ `subscription OnDeleteUserFavoriteStore(
  $filter: ModelSubscriptionUserFavoriteStoreFilterInput
  $owner: String
) {
  onDeleteUserFavoriteStore(filter: $filter, owner: $owner) {
    user_id
    store_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserFavoriteStoreSubscriptionVariables,
  APITypes.OnDeleteUserFavoriteStoreSubscription
>;
export const onCreateVisionSearchEntry = /* GraphQL */ `subscription OnCreateVisionSearchEntry(
  $filter: ModelSubscriptionVisionSearchEntryFilterInput
) {
  onCreateVisionSearchEntry(filter: $filter) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVisionSearchEntrySubscriptionVariables,
  APITypes.OnCreateVisionSearchEntrySubscription
>;
export const onUpdateVisionSearchEntry = /* GraphQL */ `subscription OnUpdateVisionSearchEntry(
  $filter: ModelSubscriptionVisionSearchEntryFilterInput
) {
  onUpdateVisionSearchEntry(filter: $filter) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVisionSearchEntrySubscriptionVariables,
  APITypes.OnUpdateVisionSearchEntrySubscription
>;
export const onDeleteVisionSearchEntry = /* GraphQL */ `subscription OnDeleteVisionSearchEntry(
  $filter: ModelSubscriptionVisionSearchEntryFilterInput
) {
  onDeleteVisionSearchEntry(filter: $filter) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVisionSearchEntrySubscriptionVariables,
  APITypes.OnDeleteVisionSearchEntrySubscription
>;
export const onCreateStylistRoom = /* GraphQL */ `subscription OnCreateStylistRoom(
  $filter: ModelSubscriptionStylistRoomFilterInput
) {
  onCreateStylistRoom(filter: $filter) {
    id
    name
    createdAt
    updatedAt
    users
    userIds
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStylistRoomSubscriptionVariables,
  APITypes.OnCreateStylistRoomSubscription
>;
export const onUpdateStylistRoom = /* GraphQL */ `subscription OnUpdateStylistRoom(
  $filter: ModelSubscriptionStylistRoomFilterInput
) {
  onUpdateStylistRoom(filter: $filter) {
    id
    name
    createdAt
    updatedAt
    users
    userIds
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStylistRoomSubscriptionVariables,
  APITypes.OnUpdateStylistRoomSubscription
>;
export const onDeleteStylistRoom = /* GraphQL */ `subscription OnDeleteStylistRoom(
  $filter: ModelSubscriptionStylistRoomFilterInput
) {
  onDeleteStylistRoom(filter: $filter) {
    id
    name
    createdAt
    updatedAt
    users
    userIds
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStylistRoomSubscriptionVariables,
  APITypes.OnDeleteStylistRoomSubscription
>;
export const onCreateStylistRoomInvite = /* GraphQL */ `subscription OnCreateStylistRoomInvite(
  $filter: ModelSubscriptionStylistRoomInviteFilterInput
) {
  onCreateStylistRoomInvite(filter: $filter) {
    id
    stylistRoomId
    completed
    completedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStylistRoomInviteSubscriptionVariables,
  APITypes.OnCreateStylistRoomInviteSubscription
>;
export const onUpdateStylistRoomInvite = /* GraphQL */ `subscription OnUpdateStylistRoomInvite(
  $filter: ModelSubscriptionStylistRoomInviteFilterInput
) {
  onUpdateStylistRoomInvite(filter: $filter) {
    id
    stylistRoomId
    completed
    completedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStylistRoomInviteSubscriptionVariables,
  APITypes.OnUpdateStylistRoomInviteSubscription
>;
export const onDeleteStylistRoomInvite = /* GraphQL */ `subscription OnDeleteStylistRoomInvite(
  $filter: ModelSubscriptionStylistRoomInviteFilterInput
) {
  onDeleteStylistRoomInvite(filter: $filter) {
    id
    stylistRoomId
    completed
    completedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStylistRoomInviteSubscriptionVariables,
  APITypes.OnDeleteStylistRoomInviteSubscription
>;
export const onCreateStylistRoomEntry = /* GraphQL */ `subscription OnCreateStylistRoomEntry(
  $filter: ModelSubscriptionStylistRoomEntryFilterInput
) {
  onCreateStylistRoomEntry(filter: $filter) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateStylistRoomEntrySubscriptionVariables,
  APITypes.OnCreateStylistRoomEntrySubscription
>;
export const onUpdateStylistRoomEntry = /* GraphQL */ `subscription OnUpdateStylistRoomEntry(
  $filter: ModelSubscriptionStylistRoomEntryFilterInput
) {
  onUpdateStylistRoomEntry(filter: $filter) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateStylistRoomEntrySubscriptionVariables,
  APITypes.OnUpdateStylistRoomEntrySubscription
>;
export const onDeleteStylistRoomEntry = /* GraphQL */ `subscription OnDeleteStylistRoomEntry(
  $filter: ModelSubscriptionStylistRoomEntryFilterInput
) {
  onDeleteStylistRoomEntry(filter: $filter) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteStylistRoomEntrySubscriptionVariables,
  APITypes.OnDeleteStylistRoomEntrySubscription
>;
