/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCloneTrainingJob = /* GraphQL */ `query GetCloneTrainingJob($id: ID!) {
  getCloneTrainingJob(id: $id) {
    id
    user_id
    status
    progress
    clone_id
    is_product_clone
    error
    completed_at
    duration
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCloneTrainingJobQueryVariables,
  APITypes.GetCloneTrainingJobQuery
>;
export const listCloneTrainingJobs = /* GraphQL */ `query ListCloneTrainingJobs(
  $id: ID
  $filter: ModelCloneTrainingJobFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCloneTrainingJobs(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user_id
      status
      progress
      clone_id
      is_product_clone
      error
      completed_at
      duration
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCloneTrainingJobsQueryVariables,
  APITypes.ListCloneTrainingJobsQuery
>;
export const cloneTrainingJobsByUserId = /* GraphQL */ `query CloneTrainingJobsByUserId(
  $user_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCloneTrainingJobFilterInput
  $limit: Int
  $nextToken: String
) {
  cloneTrainingJobsByUserId(
    user_id: $user_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      status
      progress
      clone_id
      is_product_clone
      error
      completed_at
      duration
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CloneTrainingJobsByUserIdQueryVariables,
  APITypes.CloneTrainingJobsByUserIdQuery
>;
export const getSavedProduct = /* GraphQL */ `query GetSavedProduct($user_id: ID!, $product_id: ID!) {
  getSavedProduct(user_id: $user_id, product_id: $product_id) {
    user_id
    product_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSavedProductQueryVariables,
  APITypes.GetSavedProductQuery
>;
export const listSavedProducts = /* GraphQL */ `query ListSavedProducts(
  $user_id: ID
  $product_id: ModelIDKeyConditionInput
  $filter: ModelSavedProductFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSavedProducts(
    user_id: $user_id
    product_id: $product_id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      user_id
      product_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSavedProductsQueryVariables,
  APITypes.ListSavedProductsQuery
>;
export const savedProductsByUser = /* GraphQL */ `query SavedProductsByUser(
  $user_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSavedProductFilterInput
  $limit: Int
  $nextToken: String
) {
  savedProductsByUser(
    user_id: $user_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      user_id
      product_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SavedProductsByUserQueryVariables,
  APITypes.SavedProductsByUserQuery
>;
export const savesForProduct = /* GraphQL */ `query SavesForProduct(
  $product_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSavedProductFilterInput
  $limit: Int
  $nextToken: String
) {
  savesForProduct(
    product_id: $product_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      user_id
      product_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SavesForProductQueryVariables,
  APITypes.SavesForProductQuery
>;
export const getUserFavoriteStore = /* GraphQL */ `query GetUserFavoriteStore($user_id: ID!, $store_id: ID!) {
  getUserFavoriteStore(user_id: $user_id, store_id: $store_id) {
    user_id
    store_id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserFavoriteStoreQueryVariables,
  APITypes.GetUserFavoriteStoreQuery
>;
export const listUserFavoriteStores = /* GraphQL */ `query ListUserFavoriteStores(
  $user_id: ID
  $store_id: ModelIDKeyConditionInput
  $filter: ModelUserFavoriteStoreFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserFavoriteStores(
    user_id: $user_id
    store_id: $store_id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      user_id
      store_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserFavoriteStoresQueryVariables,
  APITypes.ListUserFavoriteStoresQuery
>;
export const favoriteStoresByUser = /* GraphQL */ `query FavoriteStoresByUser(
  $user_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFavoriteStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  favoriteStoresByUser(
    user_id: $user_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      user_id
      store_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FavoriteStoresByUserQueryVariables,
  APITypes.FavoriteStoresByUserQuery
>;
export const favoritesForStore = /* GraphQL */ `query FavoritesForStore(
  $store_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFavoriteStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  favoritesForStore(
    store_id: $store_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      user_id
      store_id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FavoritesForStoreQueryVariables,
  APITypes.FavoritesForStoreQuery
>;
export const getVisionSearchEntry = /* GraphQL */ `query GetVisionSearchEntry($id: ID!) {
  getVisionSearchEntry(id: $id) {
    id
    user_id
    image_url
    result
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVisionSearchEntryQueryVariables,
  APITypes.GetVisionSearchEntryQuery
>;
export const listVisionSearchEntries = /* GraphQL */ `query ListVisionSearchEntries(
  $id: ID
  $filter: ModelVisionSearchEntryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVisionSearchEntries(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user_id
      image_url
      result
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVisionSearchEntriesQueryVariables,
  APITypes.ListVisionSearchEntriesQuery
>;
export const visionSearchEntriesByUserId = /* GraphQL */ `query VisionSearchEntriesByUserId(
  $user_id: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelVisionSearchEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  visionSearchEntriesByUserId(
    user_id: $user_id
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user_id
      image_url
      result
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VisionSearchEntriesByUserIdQueryVariables,
  APITypes.VisionSearchEntriesByUserIdQuery
>;
export const getStylistRoom = /* GraphQL */ `query GetStylistRoom($id: ID!) {
  getStylistRoom(id: $id) {
    id
    name
    createdAt
    updatedAt
    users
    userIds
    entries {
      nextToken
      __typename
    }
    modelBuild
    userStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStylistRoomQueryVariables,
  APITypes.GetStylistRoomQuery
>;
export const listStylistRooms = /* GraphQL */ `query ListStylistRooms(
  $id: ID
  $filter: ModelStylistRoomFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStylistRooms(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      users
      userIds
      modelBuild
      userStatus
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStylistRoomsQueryVariables,
  APITypes.ListStylistRoomsQuery
>;
export const getStylistRoomInvite = /* GraphQL */ `query GetStylistRoomInvite($id: ID!) {
  getStylistRoomInvite(id: $id) {
    id
    stylistRoomId
    completed
    completedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStylistRoomInviteQueryVariables,
  APITypes.GetStylistRoomInviteQuery
>;
export const listStylistRoomInvites = /* GraphQL */ `query ListStylistRoomInvites(
  $id: ID
  $filter: ModelStylistRoomInviteFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStylistRoomInvites(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      stylistRoomId
      completed
      completedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStylistRoomInvitesQueryVariables,
  APITypes.ListStylistRoomInvitesQuery
>;
export const getStylistRoomEntry = /* GraphQL */ `query GetStylistRoomEntry($id: ID!) {
  getStylistRoomEntry(id: $id) {
    id
    stylistRoomId
    senderId
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStylistRoomEntryQueryVariables,
  APITypes.GetStylistRoomEntryQuery
>;
export const listStylistRoomEntries = /* GraphQL */ `query ListStylistRoomEntries(
  $id: ID
  $filter: ModelStylistRoomEntryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listStylistRoomEntries(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      stylistRoomId
      senderId
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStylistRoomEntriesQueryVariables,
  APITypes.ListStylistRoomEntriesQuery
>;
export const stylistRoomEntriesByStylistRoomId = /* GraphQL */ `query StylistRoomEntriesByStylistRoomId(
  $stylistRoomId: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelStylistRoomEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  stylistRoomEntriesByStylistRoomId(
    stylistRoomId: $stylistRoomId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      stylistRoomId
      senderId
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StylistRoomEntriesByStylistRoomIdQueryVariables,
  APITypes.StylistRoomEntriesByStylistRoomIdQuery
>;
