'use client'

import {uploadData} from 'aws-amplify/storage'
import {useCallback, useState} from 'react'
import {v4 as uuid} from 'uuid'
import {z} from 'zod'

import {
  productRequestService,
  type ProductRequest,
} from '@atorie/api/product-requests'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'

export const ProductRequestInputSchema = z.object({
  brand: z.string().min(1),
  garment: z.string().min(1),
  link: z.string().url().optional().or(z.literal('')),
  image: z.string().or(z.any()),
})

export type ProductRequestInputSchema = z.infer<
  typeof ProductRequestInputSchema
>

function productRequestCreateMutation(onProgress: (progress: number) => void) {
  return async function productRequest(input: ProductRequestInputSchema) {
    const result = await uploadData({
      path: `public/images/${uuid()}`,
      data: input.image,
      options: {
        onProgress: ({transferredBytes, totalBytes}) => {
          if (totalBytes) {
            onProgress((transferredBytes / totalBytes) * 100)
          }
        },
      },
    }).result.catch((err) => {
      console.error('aws3', err)
      throw err
    })

    const req = await productRequestService.create({
      brand: input.brand,
      name: input.garment,
      link: input.link ?? '',
      image_keys: [result.path],
      description: '',
    })

    onProgress(0)

    return req.data
  }
}

type UseProductRequestUpdateMutationProps = Omit<
  UseMutationOptions<ProductRequest, Error, ProductRequestInputSchema>,
  'mutationFn' | 'mutationKey'
>

export function useProductRequestMutation(
  props: UseProductRequestUpdateMutationProps = {},
) {
  const [progress, setProgress] = useState<number>(0)
  const onProgress = useCallback((progress: number) => {
    setProgress(progress)
  }, [])

  const mutation = useMutation({
    mutationKey: ['product-request', 'create'],
    mutationFn: productRequestCreateMutation(onProgress),
    ...props,
  })

  return {...mutation, progress}
}

export const ProductRequestSchema = z.object({
  id: z.string(),
  name: z.string(),
  brand: z.string(),
  description: z.string(),
  image_keys: z.tuple([z.string()]),
  link: z.string().nullable(),
  notify_email: z.string().email().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  product_id: z.string().nullable(),
  owner_id: z.string().nullable(),
  image: z.string().nullable(),
  garment: z.string().nullable(),
})

export type ProductRequestSchema = z.infer<typeof ProductRequestSchema>

export const ProductRequestUpdateSchema = ProductRequestSchema.omit({
  id: true,
  created_at: true,
  updated_at: true,
  owner_id: true,
}).partial()

export type ProductRequestUpdateSchema = z.infer<
  typeof ProductRequestUpdateSchema
>

type UseProductRequestMutationProps = Omit<
  UseMutationOptions<
    ProductRequest,
    Error,
    {id: string; productRequest: ProductRequestUpdateSchema}
  >,
  'mutationFn' | 'mutationKey'
>

export function useProductRequestUpdateMutation(
  props: UseProductRequestMutationProps = {},
) {
  return useMutation({
    async mutationFn({id, productRequest}) {
      const res = await productRequestService.update(id, productRequest)
      return res.data
    },
    ...props,
  })
}
