import {getAxiosInstance, type AxiosInstance} from '@atorie/core/axios'

export interface Store {
  id: string
  external_store_id: string
  store_name: string
  profile_image?: string | null
  bio?: string | null
  metadata?: Record<string, any> | null
  created_at: Date
  updated_at: Date
}

// CreateStoreDto requires all non-optional fields from StoreDto
export type CreateStoreDto = Omit<Store, 'id' | 'created_at' | 'updated_at'>

// UpdateStoreDto makes all fields optional
export type UpdateStoreDto = Partial<
  Omit<Store, 'id' | 'external_store_id' | 'created_at' | 'updated_at'>
>

export class StoresService {
  private static instance: StoresService
  private axios: AxiosInstance

  public static getInstance(): StoresService {
    if (!StoresService.instance) {
      StoresService.instance = new StoresService()
    }

    return StoresService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async create(createStoreDto: CreateStoreDto): Promise<Store> {
    const response = await this.axios.post('/stores', createStoreDto)
    return response.data
  }

  async findAll(): Promise<Store[]> {
    const response = await this.axios.get('/stores')
    return response.data
  }

  async findByIds(ids: string[]): Promise<Store[]> {
    if (!Array.isArray(ids)) {
      throw new Error('ids must be an array')
    }
    if (ids.length === 0) {
      return []
    }
    if (ids.some(id => typeof id !== 'string' || !id)) {
      throw new Error('all ids must be non-empty strings')
    }
    const response = await this.axios.get(`/stores`, {params: {ids}})
    return response.data
  }

  async findOne(id: string): Promise<Store> {
    const response = await this.axios.get(`/stores/${id}`)
    return response.data
  }

  async update(id: string, updateStoreDto: UpdateStoreDto): Promise<Store> {
    const response = await this.axios.patch(`/stores/${id}`, updateStoreDto)
    return response.data
  }

  async remove(id: string): Promise<Store> {
    const response = await this.axios.delete(`/stores/${id}`)
    return response.data
  }

  async findStoreProducts(id: string) {
    const response = await this.axios.get(`/stores/${id}/products`)
    return response.data
  }

  async findByExternalId(externalId: string): Promise<Store> {
    const response = await this.axios.get(`/stores/external/${externalId}`)
    return response.data
  }

  async findStoreProductsPaginated(
    storeId: string,
    skip?: number,
    take?: number,
  ) {
    const response = await this.axios.get(
      `/stores/${storeId}/products/paginated?${
        skip !== undefined ? `skip=${skip}&` : ''
      }${take !== undefined ? `take=${take}` : ''}`,
    )
    return response.data
  }
}

export const storesService = StoresService.getInstance()
