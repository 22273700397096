import {shopifyService} from '@atorie/api/shopify'
import {queryOptions, useQuery} from '@tanstack/react-query'

type UseShopifyProductByIdQueryOptions = Omit<
  ReturnType<typeof vendorsQueryOptions>,
  'queryKey' | 'queryFn' | 'queryHash' | 'queryHashFn' | 'initialData'
>

export function vendorsQueryOptions() {
  return queryOptions({
    queryKey: ['shopify-vendors'] as const,
    queryFn: async ({queryKey: [_]}) => {
      return await shopifyService.getVendors()
    },
  })
}

export function useVendorsQuery(
  options: Partial<UseShopifyProductByIdQueryOptions> = {},
) {
  return useQuery({...vendorsQueryOptions(), ...options})
}
