import { useQuery } from "@tanstack/react-query";
import { userService } from "@atorie/api/users";

export interface OrderItem {
  id: string;
  name: string;
  createdAt: string;
  displayFinancialStatus: string;
  displayFulfillmentStatus: string;
  totalPriceSet: {
    shopMoney: {
      amount: string;
      currencyCode: string;
    };
  };
  customer: {
    id: string;
    email: string | null;
    displayName: string;
  };
  lineItems: {
    edges: Array<{
      node: {
        title: string;
        quantity: number;
        variant?: {
          image?: {
            originalSrc: string;
            altText: string | null;
          };
          product?: {
            images: {
              edges: Array<{
                node: {
                  originalSrc: string;
                  altText: string | null;
                };
              }>;
            };
          };
        };
        originalUnitPriceSet: {
          shopMoney: {
            amount: string;
            currencyCode: string;
          };
        };
      };
    }>;
  };
}

export interface UseOrdersOptions {
  page?: number;
  limit?: number;
}

export function useOrders(options?: UseOrdersOptions) {
  const { page = 1, limit = 10 } = options || {};

  return useQuery<{ nodes: OrderItem[]; totalPages: number }>({
    queryKey: ["orders", { page, limit }],
    queryFn: async () => {
      const first = limit;
      const after = page > 1 ? btoa(`cursor${(page - 1) * limit}`) : undefined;
      
      return userService.getOrders({ first, after });
    },
  });
}
