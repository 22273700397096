import {AxiosInstance, getAxiosInstance} from '@atorie/core/axios'

import {ProductCardFragment} from '../types/storefront.generated'

export type {ProductCardFragment}

export interface VisionAnalysisItem {
  objectName: string
  boundingPoly: BoundingPoly
  products: ProductCardFragment[]
  imageIndices: number[]
  variantIds: string[]
}
export interface BoundingPoly {
  vertices?: Vertex[]
  normalizedVertices?: NormalizedVertex[]
}
export interface Vertex {
  x?: number
  y?: number
}
export interface NormalizedVertex {
  x?: number
  y?: number
}
export interface VisionAnalysisResult {
  results: VisionAnalysisItem[]
  emptyResults: VisionAnalysisItem[]
}

export interface FaceAndLogoDetectionResult {
  logos: string[]
  face_bounding_box: {
    x: number
    y: number
    width: number
    height: number
  }
  text_blocks: {
    text: string
    bounding_box: {
      x: number
      y: number
      width: number
      height: number
    }
  }[]
}

export class VisionService {
  private static instance: VisionService
  private axios: AxiosInstance

  public static getInstance(): VisionService {
    if (!VisionService.instance) {
      VisionService.instance = new VisionService()
    }

    return VisionService.instance
  }

  private constructor() {
    this.axios = getAxiosInstance()
  }

  async analyzeImage(imageBytes: Uint8Array, signal?: AbortSignal) {
    const req = await this.axios.post<VisionAnalysisResult>(
      '/vision/product-search',
      imageBytes, // Send the raw byte array directly
      {
        headers: {
          'Content-Type': 'application/octet-stream', // Set the correct Content-Type
        },
        signal,
      },
    )
    return req.data
  }

  async analyzeImageUrl(imageUri: string, signal?: AbortSignal) {
    console.log('Analyzing image:', imageUri)
    const req = await this.axios.post<VisionAnalysisResult>(
      '/vision/product-search-url',
      {
        imageUri,
      },
      {signal},
    )
    return req.data
  }

  async detectFacesAndLogos(imageBytes: Uint8Array, signal?: AbortSignal) {
    console.log('Detecting faces and logos:', imageBytes)
    const req = await this.axios.post<FaceAndLogoDetectionResult>(
      '/vision/detect-faces-and-logos',
      imageBytes,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        signal,
      },
    )
    return req.data
  }
}

export const visionService = VisionService.getInstance()
