'use client'

import {Montserrat} from 'next/font/google'
import Image from 'next/image'
import React from 'react'

import {cx} from '@atorie/core/cva'

interface LogoProps {
  className?: string
  white?: boolean
}

const montserrat = Montserrat({
  weight: ['700'],
  subsets: ['latin'],
})

export function Logo({className, white = false}: LogoProps) {
  return (
    <div className={cx('flex w-full justify-center', className)}>
      <button
        onClick={() => window.location.replace('/')}
        className={cx(
          `w-fit text-lg tracking-wide md:text-xl ${white ? 'text-white' : 'text-zinc-800'}`,
          className,
          montserrat.className,
        )}
      >
        THE SAME SAME
      </button>
    </div>
  )
}
