export function splitIntoGroups<T>(items: T[], groupSize: number): T[][] {
  const itemsLength = items.length
  const groupsCount = Math.ceil(itemsLength / groupSize)
  const groups = new Array<T[]>(groupsCount)

  return items.reduce((acc, item, index) => {
    // 3 products per row
    const row = Math.floor(index / groupSize)

    if (!acc[row]) {
      acc[row] = []
    }

    acc[row].push(item)

    return acc
  }, groups)
}

export default splitIntoGroups
